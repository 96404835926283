.sc-our-store {
  padding-top: 1rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 3rem;
  }
  .title-page {
    font-weight: $font-weight-lighter;
    font-size: 2rem;
    line-height: 38px;
    text-align: center;
    color: $iron-gray;
    margin-bottom: 0.5rem;
  }
  .subtitle-page {
    font-weight: $font-weight-light;
    font-size: 1rem;
    line-height: 19px;
    text-align: center;
    color: rgba($iron-gray, 0.7);
    margin-bottom: 2.5rem;
  }
  .title-content {
    font-weight: $font-weight-lighter;
    font-size: 1.5rem;
    line-height: 29px;
    margin-bottom: 1.25rem;
  }
  .text-label {
    font-weight: $font-weight-light;
    font-size: 0.75rem;
    line-height: 14px;
    margin-bottom: 0.5rem;
    color: $fossil-gray;
  }
  .text-value {
    font-weight: $font-weight-light;
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 1.25rem;
    &.grid {
      display: grid;
      grid-template-columns: 1fr auto;
      // column-gap: 1rem;
      p:nth-child(even) {
        white-space: nowrap;
        text-align: right;
      }
    }
    p {
      font-weight: $font-weight-light;
      font-size: 1rem;
      line-height: 150%;
      margin-bottom: 0;
    }
  }
}
