.sc-mission-statement {
  padding-top: 1rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-bottom: 91px;
  }
  .wrapper-content {
    .title {
      font-weight: $font-weight-lighter;
      text-align: left;
      color: $iron-gray;
      font-size: 1.5rem;
      line-height: 29px;
      margin-bottom: 2rem;
      @include media-breakpoint-up(md) {
        font-size: 2rem;
        line-height: 38px;
        margin-bottom: 1rem;
      }
    }
    .text {
      font-weight: $font-weight-lighter;
      font-size: 1rem;
      line-height: 28px;
      color: $iron-gray;
    }
  }
}

.sc-export-history {
  position: relative;
  padding-top: 75px;
  @include media-breakpoint-up(md) {
    padding-top: 91px;
    padding-bottom: 151px;
  }
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 204px;
    left: 0;
    top: 0;
    background-color: rgba($pearl-river, 0.4);
    @include media-breakpoint-up(md) {
      height: 433px;
      top: unset;
      bottom: 0;
    }
  }
  .row {
    position: relative;
    .col-content {
      padding: 0 1.625rem;
      margin-top: -2.5rem;
      @include media-breakpoint-up(md) {
        padding: unset;
        position: absolute;
        top: 153px;
        right: 0;
        margin-top: 0;
      }
      .wrapper-content {
        background-color: $white;
        border: 1px solid $pearl-river;
        padding: 1.5rem;
        @include media-breakpoint-up(md) {
          padding: 2rem;
        }
        .title {
          font-weight: $font-weight-lighter;
          font-size: 2rem;
          line-height: 38px;
          margin-bottom: 1rem;
          color: $iron-gray;
        }
        .text {
          font-weight: $font-weight-lighter;
          font-size: 1rem;
          line-height: 28px;
          color: $iron-gray;
          margin-bottom: 1rem;
          a {
            color: $iron-gray;
            padding-bottom: 0.5rem;
          }
        }
      }
    }
  }
}

.sc-sustain {
  padding-top: 74px;
  padding-bottom: 80px;
  @include media-breakpoint-up(md) {
    padding-top: 200px;
    padding-bottom: 252px;
  }
  .wrapper-img {
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
  .wrapper-content {
    .title {
      font-weight: $font-weight-lighter;
      font-size: 2rem;
      line-height: 38px;
      margin-bottom: 1rem;
      color: $iron-gray;
    }
    .text {
      font-weight: $font-weight-lighter;
      font-size: 1rem;
      line-height: 28px;
      color: $iron-gray;
    }
  }
}

.sc-handmade {
  background: linear-gradient(180deg, $white 50%, $pearl-river 50%);
  height: 715px;
  @include media-breakpoint-down(sm) {
    height: 335px;
  }
  .container {
    height: 100%;
    .wrapper-content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .img-bottom-about {
        max-width: 872px;
        max-height: 490px;
      }
    }
  }
}
