.sc-help {
  padding-top: 1rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 3rem;
  }
  .search-field-wrapper {
    position: relative;
    .search-field {
      // width: 100%;
      .form-control-wrapper {
        &.ic-left {
          &:before {
            font-family: "Anti Icons Regular", sans-serif;
            content: varIcon($ai-search);
            color: $black;
          }
        }
      }
    }
    > i {
      right: 0;
      bottom: 0.5rem;
      position: absolute;
      font-size: 1.5rem;
      color: $black;
      font-weight: 400;
      &.ai-times {
        cursor: pointer;
      }
    }
  }
  .common-loader {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    padding: 1.5rem 0;
    i {
      animation: spin 2s infinite linear;
    }
  }

  .wrapper-header {
    .title-page {
      font-weight: $font-weight-lighter;
      text-align: center;
      color: $iron-gray;
      font-size: 1.5rem;
      line-height: 29px;
      margin-bottom: 2rem;
      @include media-breakpoint-up(md) {
        font-size: 2rem;
        line-height: 38px;
        margin-bottom: 2.5rem;
      }
    }
    .list-menu {
      display: flex;
      flex-wrap: nowrap;
      overflow: auto;
      gap: 2rem;
      margin-left: -1.25rem;
      margin-right: -1.25rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      margin-bottom: 2rem;
      @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 0;
        margin-bottom: 2.5rem;
        align-items: center;
      }
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      .menu-item {
        @extend .cursor-pointer;
        .menu-title {
          @extend .mx-auto;
          font-weight: $font-weight-light;
          font-size: 1rem;
          line-height: 19px;
          margin-bottom: 0;
          color: $iron-gray;
          text-align: center;
          width: fit-content;
          white-space: nowrap;
        }
        &.active {
          .menu-title {
            // text-decoration: underline;
            border-bottom: 1px solid $iron-gray;
          }
        }
      }
    }
  }
  .wrapper-content {
    .desc-box {
      border: 1px solid $pearl-river;
      padding: 1.5rem;
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      @include media-breakpoint-up(md) {
        padding: 2rem;
      }
      .accordion {
        border-bottom: none !important;
        .accordion-header {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          .accordion-title {
            font-weight: $font-weight-medium;
            font-size: 1.125rem;
            line-height: 22px;
            color: $iron-gray;
          }
        }
        .accordion-content {
          margin-top: 1.125rem;
          font-family: $headings-font-family;
          font-weight: $font-weight-lighter;
          font-size: 1rem;
          line-height: 28px;
        }
      }
    }
  }
  // .box-list {
  //   display: flex;
  //   flex-direction: column;
  //   gap: 1rem;
  //   @include media-breakpoint-down(sm) {
  //     margin-bottom: 1.5rem;
  //     flex-direction: row;
  //     flex-wrap: nowrap;
  //     overflow: auto;
  //   }
  //   .help-box {
  //     cursor: pointer;
  //     border: 1px solid $pearl-river;
  //     p {
  //       padding: 0;
  //       margin: 0;
  //       white-space: nowrap;
  //       @include media-breakpoint-down(sm) {
  //         @include text-title-3-mobile;
  //       }
  //     }
  //     &.active {
  //       background-color: $iron-gray;
  //       border: 1px solid $fossil-gray;
  //       color: $white;
  //     }
  //   }
  // }
  // .desc-box {
  //   border: 1px solid $iron-gray;
  //   padding: 0 2rem;
  //   margin-bottom: 1rem;
  //   .accordion-title {
  //     @include text-title-3;
  //     @include media-breakpoint-down(sm) {
  //       @include text-title-3-mobile;
  //     }
  //   }
  //   .accordion-content {
  //     @include text-body-1;
  //     color: $iron-gray;
  //     @include media-breakpoint-down(sm) {
  //       @include text-body-3;
  //     }
  //   }
  //   .accordion.accordion-line .accordion-header {
  //     padding-top: 1.75rem !important;
  //     padding-bottom: 1.75rem !important;
  //   }
  // }
}
