.card-product {
  position: relative;
  margin-bottom: 1.5rem;

  &:hover {
    .card-product-image {
      .bg-wrapper-button {
        opacity: 1;
        visibility: visible;
      }
      .wrapper-button {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        .btn {
          transform: translateY(0);
        }
      }
    }
  }
  .badge-wrapper {
    position: absolute;
    left: 12px;
    top: 12px;
    display: flex;
    gap: 8px;
    .badge {
      text-transform: uppercase;
      font-size: 0.75rem;
      line-height: 1;
      padding: 0.5rem;
      &.new {
        color: $iron-gray;
        border: 1px solid $iron-gray;
      }

      &.sale {
        color: #e17958;
        border: 1px solid #e17958;
      }
    }
  }

  .card-product-image {
    position: relative;

    .wrapper-img {
      .out-stock-overlay {
        position: absolute;
        left: 50%;
        bottom: 16px;
        background-color: rgba($fossil-gray, 0.5);
        transform: translateX(-50%);
        padding: 0.5rem;
        border-radius: 40px;
        width: fit-content;
        p {
          text-transform: uppercase;
          color: $white;
          font-weight: $font-weight-medium;
          font-size: 0.625rem;
          line-height: 12px;
          margin-bottom: 0;
        }
      }
    }
    .bg-wrapper-button {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: $transition-base;
      position: absolute;
      inset: 0;
      background-color: rgba($black, 0.2);
    }
    .wrapper-button {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: $transition-base;
      position: absolute;
      // top: 0;
      left: 0;
      width: 100%;
      // height: 100%;
      top: 50%;
      transform: translateY(-50%);
      // background-color: rgba($black, 0.2);
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 10px;
      gap: 0.5rem;
      display: none;
      @include media-breakpoint-up(lg) {
        display: flex;
      }
      .btn {
        font-family: $font-family-base;
        font-weight: $font-weight-light;
        font-size: 0.875rem;
        line-height: 20px;
        color: $iron-gray;
        transform: translateY(1rem);
        transition: $transition-base;
      }
    }
  }

  .card-product-wrapper {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: 1fr min-content;
    gap: 0.75rem;
    .card-product-wrapper__left {
      .category-title {
        font-size: 0.75rem;
        line-height: 1.2;
        margin-bottom: 0.25rem;
        color: rgba($body-color, 0.5);
        @include media-breakpoint-up(md) {
          font-size: 1rem;
          line-height: 18px;
        }
      }

      .card-product-title {
        font-weight: $font-weight-lighter;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        @include media-breakpoint-up(md) {
          font-size: 1rem;
          line-height: 19px;
        }
      }

      .card-product-price {
        .grand-total {
          font-weight: $font-weight-lighter;
          font-size: 1rem;
          line-height: 19px;
          margin-bottom: 0;
        }
        .subtotal {
          text-decoration: line-through;
          font-weight: $font-weight-lighter;
          font-size: 0.875rem;
          line-height: 17px;
          color: rgba($body-color, 0.75);
          margin-bottom: 0;
        }
      }
    }
    .icon-wrapper {
      .icon-heart {
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
        transition: $transition-base;
      }
    }
  }
}
