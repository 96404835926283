@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?c59x9n");
  src: url("fonts/icomoon.eot?c59x9n#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?c59x9n") format("truetype"),
    url("fonts/icomoon.woff?c59x9n") format("woff"),
    url("fonts/icomoon.svg?c59x9n#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon", sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-qty-min:before {
  content: "\e903";
}
.icon-qty-plus:before {
  content: "\e904";
}
.icon-envelope:before {
  content: "\e902";
}
.icon-heart:before {
  content: "\e900";
}
.icon-shopping-bag:before {
  content: "\e901";
}
