// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// Override style on .page-transition-custom.scss instead
// unless you want to upgrade the component version

// ========================================================================================================================================
// Page Transitions
// ========================================================================================================================================

.panel-background {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: $zindex-top-3;
  left: 0;
  background-color: $dark;
}
