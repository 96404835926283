.sc-wishlist {
  padding-top: 1rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 3rem;
  }
  .title-page {
    font-weight: $font-weight-lighter;
    text-align: center;
    color: $iron-gray;
    font-size: 1.5rem;
    line-height: 29px;
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      font-size: 2rem;
      line-height: 38px;
      margin-bottom: 2.5rem;
    }
  }
  .title-content {
    font-weight: $font-weight-lighter;
    font-size: 1.5rem;
    line-height: 29px;
  }
  .infinite-scroll-component {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    @extend .mx-n1;
    @include media-breakpoint-down(md) {
      overflow-x: hidden !important;
    }
  }
}

.wishlist-blank {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  text-align: center;
  @include media-breakpoint-up(md) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .top {
    .wrapper-icon {
      width: 125.22px;
      height: 125.22px;
      border-radius: 50%;
      border: 1px solid $iron-gray;
      @extend .flex-center;
      margin: 0 auto 2.25rem;
      .img-wishlist-top {
        width: 4rem;
        height: 4rem;
      }
    }
    .title-content {
      font-weight: $font-weight-lighter;
      font-size: 2rem;
      line-height: 29px;
      margin-bottom: 0.5rem;
    }
    .subtitle-content {
      font-weight: $font-weight-light;
      font-size: 1rem;
      line-height: 19px;
      text-align: center;
      margin-bottom: 0;
      color: rgba($iron-gray, 0.7);
    }
  }
  .category-list {
    p {
      font-weight: $font-weight-semibold;
    }
  }
}
