.sc-account {
  padding-top: 1rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 3rem;
  }
  .wrapper-header {
    .title-page {
      font-weight: $font-weight-lighter;
      text-align: center;
      color: $iron-gray;
      font-size: 1.5rem;
      line-height: 29px;
      margin-bottom: 2rem;
      @include media-breakpoint-up(md) {
        font-size: 2rem;
        line-height: 38px;
        margin-bottom: 2.5rem;
      }
    }
    .list-menu {
      display: flex;
      flex-wrap: nowrap;
      overflow: auto;
      gap: 2rem;
      margin-left: -1.25rem;
      margin-right: -1.25rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      margin-bottom: 2rem;
      @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0;
        margin-bottom: 2.5rem;
        align-items: center;
      }
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      .menu-item {
        @extend .cursor-pointer;
        .menu-title {
          @extend .mx-auto;
          font-weight: $font-weight-light;
          font-size: 1rem;
          line-height: 19px;
          margin-bottom: 0;
          color: $iron-gray;
          text-align: center;
          width: fit-content;
          white-space: nowrap;
        }
        &.active {
          .menu-title {
            // text-decoration: underline;
            border-bottom: 1px solid $iron-gray;
          }
        }
      }
    }
  }
  .account-detail-wrapper {
    .title-content {
      font-weight: $font-weight-light;
      font-size: 1.5rem;
      line-height: 29px;
      margin-bottom: 0.25rem;
    }
    .subtitle-content {
      font-size: 1rem;
      line-height: 24px;
      margin-bottom: 1.5rem;
      color: rgba($iron-gray, 0.7);
    }
    .sc-account-details-form {
      padding: 1.5rem;
      border: 1px solid $pearl-river;
      @include media-breakpoint-up(md) {
        padding: 2rem;
      }
      .label-check {
        font-weight: $font-weight-light;
        font-size: 0.875rem;
        line-height: 17px;
        margin-bottom: 0;
      }
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }
  .sc-shipping-details {
    // padding: 1.5rem;
    // border: 1px solid $pearl-river;
    // @include media-breakpoint-up(md) {
    //   padding: 2rem;
    // }
    .title-content {
      font-weight: $font-weight-light;
      font-size: 1.5rem;
      line-height: 29px;
      margin-bottom: 0.25rem;
    }
    .subtitle-content {
      font-size: 1rem;
      line-height: 24px;
      margin-bottom: 1.5rem;
      color: rgba($iron-gray, 0.7);
    }
    .list-address {
      display: grid;
      gap: 1.5rem;
      .address-item {
        padding: 1.5rem;
        border: 1px solid $pearl-river;
        @include media-breakpoint-up(md) {
          padding: 2rem;
        }
        .header-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;

          h4 {
            font-weight: $font-weight-light;
            font-size: 1.25rem;
            margin-bottom: 0;
          }
          .label-primary {
            font-weight: $font-weight-light;
            font-size: 1rem;
            line-height: 19px;
            margin-bottom: 0;
          }
          // .radio-address {
          //   cursor: pointer;
          //   width: 1.5rem;
          //   height: 1.5rem;
          //   border-radius: 50%;
          //   border: 1px solid $iron-gray;
          //   position: relative;

          //   .circle {
          //     position: absolute;
          //     width: 1rem;
          //     height: 1rem;
          //     background-color: transparent;
          //     border-radius: 50%;
          //     top: 3px;
          //     left: 3px;
          //   }

          //   &.active {
          //     .circle {
          //       background-color: $iron-gray;
          //     }
          //   }
          // }
        }
        .content-wrapper {
          .ship-detail {
            margin-bottom: 1rem;
            .ship-detail__value {
              font-weight: $font-weight-light;
              font-size: 1rem;
              line-height: 24px;
              margin-bottom: 0rem;
              color: rgba($body-color, 0.7);
            }
          }
          .btn-wrapper {
            @extend .flex-center-between;
            .btn-edit {
              font-size: 16px;
              line-height: 18px;
              text-decoration: underline;
              outline: none;
              margin-bottom: 0;
            }
            .btn-delete {
              @extend .btn-edit;
              color: $red;
            }
          }
        }
      }
    }
  }
  .sc-order-history {
    .history-list {
      display: grid;
      gap: 1.5rem;
      .history-box {
        padding: 1.5rem;
        border: 1px solid $pearl-river;
        display: grid;
        @include media-breakpoint-up(md) {
          padding: 2rem;
          grid-template-columns: 240px 1px 1fr;
          gap: 1.5rem;
        }
        .box-left {
          .order-label {
            font-weight: $font-weight-light;
            font-size: 0.75rem;
            line-height: 14px;
            color: $fossil-gray;
            margin-bottom: 0.5rem;
          }
          .order-value {
            font-weight: $font-weight-lighter;
            font-size: 1.25rem;
            line-height: 24px;
            margin-bottom: 1rem;
          }
          .date-label {
            color: rgba($body-color, 0.7);
            margin-right: 0.5rem;
          }
          .date-value {
            font-weight: $font-weight-light;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 0.5rem;
          }
          .btn-wrapper {
            display: grid;
            gap: 1rem;
            margin-top: 2rem;
          }
        }
        .divider {
          border-bottom: 1px solid $pearl-river;
          width: 100%;
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
          @include media-breakpoint-up(md) {
            margin-top: unset;
            margin-bottom: unset;
            border-bottom: none;
            border-right: 1px solid $pearl-river;
            height: 100%;
          }
        }
        .box-right {
          display: grid;
          gap: 1.5rem;
          grid-template-rows: auto 1px auto;
          .box-order-list {
            display: grid;
            gap: 1.5rem;
            .box-order-item {
              display: grid;
              grid-template-columns: 80px 1fr;
              gap: 1rem;
              .box-order-item__content {
                .title-product {
                  font-weight: $font-weight-lighter;
                  font-size: 1.25rem;
                  line-height: 24px;
                  margin-bottom: 0.5rem;
                }
                .box-order-item__price {
                  display: grid;
                  @include media-breakpoint-up(md) {
                    grid-template-columns: 1fr auto;
                    align-items: flex-end;
                  }
                  .left-text {
                    font-weight: $font-weight-light;
                    font-size: 0.875rem;
                    line-height: 24px;
                    margin-bottom: 0.5rem;
                    color: rgba($body-color, 0.7);
                  }
                  .right-text {
                    font-weight: $font-weight-light;
                    font-size: 0.875rem;
                    line-height: 17px;
                    margin-bottom: 0;
                    @include media-breakpoint-up(md) {
                      text-align: right;
                      margin-bottom: 0.5rem;
                    }
                  }
                }
              }
            }
          }
          .divider-box {
            border-top: 1px solid $pearl-river;
            width: 100%;
          }
          .box-order-summary {
            display: grid;
            gap: 0.5rem;
            .summary-item {
              display: flex;
              justify-content: space-between;
              .summary-label {
                font-weight: $font-weight-light;
                font-size: 1rem;
                line-height: 150%;
                margin-bottom: 0;
                color: rgba($body-color, 0.7);
              }
              .summary-value {
                text-align: right;
                font-weight: $font-weight-lighter;
                font-size: 1rem;
                line-height: 150%;
                margin-bottom: 0;
              }
              .total-label,
              .total-value {
                font-weight: $font-weight-light;
                font-size: 1.125rem;
                line-height: 150%;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
