.sc-payment {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 3rem;
  }
  .title-content {
    font-weight: $font-weight-lighter;
    font-size: 1.5rem;
    line-height: 29px;
    margin-bottom: 1.5rem;
  }
  .wrapper-bank-info {
    padding-top: 2.5rem;
    @include media-breakpoint-up(md) {
      padding-top: 0;
      padding-bottom: 3rem;
    }
    .title-page {
      font-weight: $font-weight-lighter;
      text-align: center;
      color: $iron-gray;
      font-size: 1.5rem;
      line-height: 29px;
      margin-bottom: 0.5rem;
      @include media-breakpoint-up(md) {
        font-size: 2rem;
        line-height: 38px;
      }
    }
    .subtitle-page {
      font-weight: $font-weight-light;
      font-size: 1rem;
      line-height: 19px;
      text-align: center;
      color: rgba($iron-gray, 0.7);
      margin-bottom: 1rem;
      @include media-breakpoint-up(md) {
        margin-bottom: 2.5rem;
      }
    }
    .wrapper-order-number {
      padding: 1.5rem;
      border: 1px solid $pearl-river;
      color: $iron-gray;
      @include media-breakpoint-up(md) {
        padding: 1.5rem 2rem;
      }
      .label-order {
        font-weight: $font-weight-light;
        font-size: 0.875rem;
        line-height: 17px;
        margin-bottom: 0.5rem;
        text-align: center;
      }
      .order-number {
        font-weight: $font-weight-light;
        font-size: 1.5rem;
        line-height: 29px;
        text-align: center;
        margin-bottom: 0.5rem;
      }
      .expired-order {
        font-weight: $font-weight-light;
        font-size: 0.75rem;
        line-height: 14px;
        margin-bottom: 0.5rem;
        text-align: center;
        color: rgba($iron-gray, 0.8);
      }
      .wrapper-payment-method {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .payment-item {
          .desc {
            font-weight: $font-weight-light;
            font-size: 0.75rem;
            line-height: 14px;
            margin-bottom: 0.5rem;
            color: $fossil-gray;
          }
          .value {
            font-weight: $font-weight-light;
            font-size: 1rem;
            line-height: 19px;
            margin-bottom: 0;
          }
        }
      }
    }
    .wrapper-instruction {
      padding: 1.5rem 1.5rem 0;
      border: 1px solid $pearl-river;
      color: $iron-gray;
      margin-top: 1.5rem;
      @include media-breakpoint-up(md) {
        padding: 1.5rem 2rem;
      }
      .payment-instruction {
        .text-content {
          font-weight: $font-weight-light;
          font-size: 1rem;
          line-height: 24px;
          margin-bottom: 0.5rem;
        }
        .box-payment {
          padding: 1rem;
          border: 1px solid $pearl-river;
          .name-bank,
          .name-account {
            font-weight: $font-weight-light;
            font-size: 0.75rem;
            line-height: 14px;
            color: rgba($body-color, 0.7);
            margin-bottom: 0.5rem;
          }
          .number-bank {
            font-weight: $font-weight-medium;
            font-size: 1rem;
            line-height: 19px;
            margin-bottom: 0.5rem;
          }
        }
        .step {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
        }

        .v-stepper {
          position: relative;
        }

        .step .stepper-circle {
          border: 1px solid $gray-800;
          border-radius: 50%;
          width: 1.5rem;
          height: 1.5rem;
          text-align: center;
          background-color: $white;
          font-family: $headings-font-family;
          font-size: 0.75rem;
          @extend .flex-center;
        }

        .step .stepper-line {
          top: 1.5rem;
          left: 0.75rem;
          height: 100%;
          position: absolute;
          border-left: 1px dashed $gray-800;
        }
        .step:last-child .stepper-line {
          border-left: none;
        }
        .stepper-content {
          margin-left: 2rem;
          margin-bottom: 2rem;
          width: 100%;
          @include media-breakpoint-down(sm) {
            margin-left: 1rem;
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }
  .wrapper-order-information {
    padding: 1.5rem;
    border: 1px solid $pearl-river;
    color: $iron-gray;
    margin-top: 1.5rem;
    @include media-breakpoint-up(md) {
      padding: 2rem;
    }
    .content-header {
      .wrapper-detail {
        display: grid;
        gap: 1rem;
        margin-bottom: 1.5rem;
        @include media-breakpoint-up(md) {
          grid-template-columns: 1fr 1fr;
        }
        .detail-item {
          &.right-side {
            @include media-breakpoint-up(md) {
              text-align: right;
            }
          }
          .order-label {
            font-weight: $font-weight-light;
            font-size: 0.75rem;
            line-height: 14px;
            color: $fossil-gray;
            margin-bottom: 0.5rem;
          }
          .order-value {
            font-weight: $font-weight-lighter;
            font-size: 1.25rem;
            line-height: 24px;
            margin-bottom: 0;
          }
          .date-label {
            color: rgba($body-color, 0.7);
            margin-right: 0.5rem;
          }
          .date-value {
            font-weight: $font-weight-light;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
    .order-list {
      display: grid;
      gap: 1.5rem;
      .order-item {
        display: grid;
        grid-template-columns: 80px 1fr;
        gap: 1rem;
        .wrapper-order-item__content {
          display: flex;
          flex-direction: column;
          .title-product {
            font-weight: $font-weight-lighter;
            font-size: 1.25rem;
            line-height: 24px;
            margin-bottom: 0.5rem;
          }
          .wrapper-order-item__price {
            display: grid;
            @include media-breakpoint-up(md) {
              align-items: flex-end;
              grid-template-columns: 1fr auto;
            }
            .left-text {
              font-weight: $font-weight-light;
              font-size: 0.875rem;
              line-height: 24px;
              margin-bottom: 0.5rem;
              color: rgba($body-color, 0.7);
            }
            .right-text {
              font-weight: $font-weight-light;
              font-size: 0.875rem;
              line-height: 17px;
              margin-bottom: 0;
              @include media-breakpoint-up(md) {
                margin-bottom: 0.5rem;
                text-align: right;
              }
            }
          }
        }
      }
    }
    .summary-wrapper {
      display: grid;
      gap: 0.5rem;
      .summary-item {
        display: flex;
        justify-content: space-between;
        .summary-label {
          font-weight: $font-weight-light;
          font-size: 1rem;
          line-height: 150%;
          margin-bottom: 0;
          color: rgba($body-color, 0.7);
        }
        .summary-value {
          text-align: right;
          font-weight: $font-weight-lighter;
          font-size: 1rem;
          line-height: 150%;
          margin-bottom: 0;
        }
        .total-label,
        .total-value {
          font-weight: $font-weight-light;
          font-size: 1.125rem;
          line-height: 150%;
          margin-bottom: 0;
        }
      }
    }
  }
  .wrapper-shipped-info {
    border: 1px solid $pearl-river;
    color: $iron-gray;
    padding: 1.5rem;
    margin-top: 1.5rem;
    @include media-breakpoint-up(md) {
      padding: 2rem;
    }
    .shipped-info-wrapper {
      display: grid;
      gap: 0.5rem;
      .shipper-info-item {
        display: flex;
        justify-content: space-between;
        .info-label {
          font-weight: $font-weight-light;
          font-size: 1rem;
          line-height: 150%;
          margin-bottom: 0;
          color: rgba($body-color, 0.7);
        }
        .info-value {
          text-align: right;
          font-weight: $font-weight-lighter;
          font-size: 1rem;
          line-height: 150%;
          margin-bottom: 0;
        }
      }
    }
  }
  .wrapper-success {
    padding-top: 3rem;
    padding-bottom: 3rem;
    .checkmark {
      display: block;
      margin: auto;
      margin-bottom: 1.25rem;
    }
    .title {
      font-weight: $font-weight-lighter;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 0.5rem;
      text-align: center;
      @include media-breakpoint-up(md) {
        font-size: 2rem;
        line-height: 38px;
      }
    }
    .subtitle {
      font-weight: $font-weight-light;
      font-size: 1rem;
      line-height: 19px;
      text-align: center;
      margin-bottom: 1.5rem;
      color: rgba($iron-gray, 0.7);
    }
    .btn-wrapper {
      margin: auto;
      display: grid;
      gap: 1rem;
      @include media-breakpoint-up(md) {
        gap: 1.5rem;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  // .content-wrapper {
  //   margin: auto;
  //   max-width: 650px;
  //   .btn.continue-btn {
  //     border: 1px solid $iron-gray;
  //     @include media-breakpoint-down(sm) {
  //       width: 100%;
  //     }
  //   }
  // }

  // .content {
  //   width: 100%;
  //   //  min-height: 30rem;
  //   position: relative;
  //   padding: 2.5rem;
  //   margin-bottom: 1rem;
  //   @include media-breakpoint-down(sm) {
  //     padding: 1.5rem 1rem;
  //   }
  //   &.waiting {
  //     border: 1px solid $gray-400;
  //     .order-number {
  //       background: $fossil-gray;
  //       color: $white;
  //     }
  //     .method-wrapper {
  //       .text-body-4 {
  //         color: $iron-gray;
  //       }
  //     }
  //     .text-body-3,
  //     .text-caption-1 {
  //       color: rgba($gray-800, 0.7);
  //     }
  //   }

  //   &.order-details,
  //   .order-details {
  //     .content-header {
  //       .text-title-2 {
  //         @include media-breakpoint-down(sm) {
  //           @include text-title-3;
  //           &.code-order {
  //             @include text-title-3-mobile;
  //           }
  //         }
  //       }
  //       .text-body-4 {
  //         color: rgba($gray-800, 0.7);
  //         &.date {
  //           color: $gray-800;
  //         }
  //       }
  //     }
  //     .items {
  //       .text-title-3 {
  //         @include media-breakpoint-down(sm) {
  //           @include text-title-4;
  //           margin-bottom: 0.5rem;
  //         }
  //       }
  //       .text-body-4 {
  //         color: $iron-gray;
  //       }
  //       .text-body-3 {
  //         @include media-breakpoint-down(sm) {
  //           @include text-caption-2;
  //           color: $gray-800;
  //           &.price {
  //             @include text-body-3;
  //           }
  //         }
  //       }
  //       .summary-wrapper {
  //         .text-body-4 {
  //           color: rgba($gray-800, 0.7);
  //         }
  //       }
  //     }
  //   }

  //   &.shipping {
  //     .text-title-2 {
  //       @include media-breakpoint-down(sm) {
  //         @include text-title-3;
  //         &.code-order {
  //           @include text-title-3-mobile;
  //         }
  //       }
  //     }
  //   }

  //   &.success-content {
  //     @include media-breakpoint-down(sm) {
  //       padding: 2.5rem 0;
  //     }
  //     .header-content {
  //       text-align: center;
  //       padding-top: 3.75rem;
  //       padding-bottom: 1.75rem;
  //       background: $fossil-gray;
  //       color: $white;
  //     }

  //     .success-summary {
  //       background: $white;
  //       padding: 2.5rem;
  //       border: 1px solid $iron-gray;
  //       @include media-breakpoint-down(sm) {
  //         padding: 2.5rem 1rem;
  //       }
  //       p {
  //         margin-bottom: 0.625rem;
  //       }
  //       .text-body-3 {
  //         color: rgba($gray-800, 0.7);
  //         &.text-right {
  //           color: $gray-800;
  //         }
  //       }
  //       .border-dashed {
  //         border-bottom: 1px dashed $fossil-gray;
  //         margin-top: 2.5rem;
  //         margin-bottom: 2.5rem;
  //       }
  //     }

  //     .circle {
  //       background-color: $white;
  //       border-radius: 50%;
  //       position: absolute;
  //       left: 50%;
  //       top: 2.5rem;
  //       transform: translate(-50%, -50%);
  //       height: 5rem;
  //       width: 5rem;
  //       .subcircle {
  //         height: 100%;
  //         width: 100%;
  //         border-radius: 50%;
  //         background-color: #dadedc;
  //         border: 1px solid black;

  //         i {
  //           color: $white;
  //           font-size: 2.4rem;
  //           font-weight: bold;
  //         }
  //       }
  //     }
  //   }
  // }
}
