// Typography
// Version 2.1.1
//
// Font, line-height, and color for body text, headings, and more.

// Responsive font size base
$rfs-base-font-size:          1rem; // Antikode

// Font weight
$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-semibold:        600; // Antikode
$font-weight-bold:            700; // Antikode
$font-weight-bolder:          bolder; // Antikode

// Custom font import
@include font-face("Nimbus", "NimbusSanL-Reg", $font-weight-normal);
@include font-face("Nimbus", "NimbusSanL-Bol", $font-weight-bold);

// Font default / fallback (DO NOT EDIT)
$font-default-sans-serif:     -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default; // Antikode
$font-default-serif:          Georgia, serif, "Palatino Linotype", "Book Antiqua", Palatino, serif, "Times New Roman", Times, serif !default; // Antikode
$font-default-monospace:      SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default; // Antikode

// Add custom font in front of the $font-default-
// e.g. "Proxima Nova", $font-default-sans-serif
$font-family-sans-serif:      $font-default-sans-serif; // Antikode
$font-family-serif:           $font-default-serif; // Antikode
$font-family-monospace:       $font-default-monospace; // Antikode

// After setting the custom font, set the $font-family-base & $headings-font-family here
$font-family-base:            "Nimbus", $font-family-sans-serif; // Antikode
$headings-font-family:        "Nimbus", $font-family-sans-serif; // Antikode
$gillsans-font-family:        "Nimbus", $font-family-sans-serif; // Antikode

// Set options for headings here
$headings-font-weight:        $font-weight-medium; // Antikode
$headings-margin-bottom:      $spacer / 2;
$headings-line-height:        1.2;
$headings-color:              null; 

$line-height-base:            1.5;
$line-height-lg:              1.5;
$line-height-sm:              1.5;

$font-size-base:              1rem;
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .875;

$font-weight-base:            $font-weight-normal;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

// prettier-ignore
@mixin h1 { @include font-size($h1-font-size); }
@mixin h2 { @include font-size($h2-font-size); }
@mixin h3 { @include font-size($h3-font-size); }
@mixin h4 { @include font-size($h4-font-size); }
@mixin h5 { @include font-size($h5-font-size); }
@mixin h6 { @include font-size($h6-font-size); }

$lead-font-size:              $font-size-base * 1.125; // Antikode
$lead-font-weight:            300;

$small-font-size:             87.5%; // Antikode

$text-muted:                  $gray-400; // Antikode

$hr-border-color:             rgba($black, .05); // Antikode
$hr-border-width:             $border-width;