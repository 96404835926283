// ========================================================================================================================================
// Utils - Custom
// ========================================================================================================================================

// Add custom styles here
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.box {
  padding: 1.5rem;

  &.box-gray {
    background-color: #f3f3f3;
  }
}

.cursor-pointer {
  cursor: pointer;
}
