// ========================================================================================================================================
// Buttons - Custom
// ========================================================================================================================================

// ==========================================================================
// Hover Color
// ==========================================================================

// Set hover darken color (in %)
$btn-hover-bg-darken-custom: $btn-hover-bg-darken; // EDIT THIS AS NEEDED (Default: 5%)

.btn {
  @each $color, $value in $theme-colors {
    &.btn-outline-#{$color} {
      @include hover-focus {
        background-color: darken($value, $btn-hover-bg-darken-custom);
        border-color: darken($value, $btn-hover-bg-darken-custom);
      }
    }

    &.btn-#{$color} {
      @include hover-focus {
        background-color: darken($value, $btn-hover-bg-darken-custom);
        border-color: darken($value, $btn-hover-border-darken);
      }
    }
  }
}

// ==========================================================================
// Hover Effect
// ==========================================================================

// Toggle hover block right effect for filled & outline buttons
//
// .btn:not(.btn-link) {
//   @include hover-blockRight;
// }

// Toggle hover underline effect for link buttons
//
// .btn-link {
//   @include hover-underline;
//   &:before {
//     bottom: 0.25rem; // underline effect position (default: 0)
//   }
// }

// ==========================================================================
// General
// ==========================================================================

// Add customs styles here
.btn-fluid {
  width: 100%;
}

.btn {
  // @include text-button;
  & > span {
    @extend .flex-center;
  }

  &.btn-primary {
    // font-weight: bold;
    color: $iron-gray;
  }

  &.btn-secondary {
    background-color: $black;
    color: $white;
    // font-weight: $font-weight-bold;
  }

  &.btn-white {
    background-color: $white;
    border: 1px solid rgba($fossil-gray, 0.5);
    color: $iron-gray;
    // font-weight: $font-weight-bold;
    &:hover {
      background-color: darken($white, 5%);
    }
  }

  &.btn-transparent {
    background-color: transparent;
    border: 1px solid $black;
    // font-weight: $font-weight-bold;
    color: $iron-gray;
    &:hover {
      background-color: rgba($gray-800, 0.1);
    }
  }

  &.btn-outline-secondary {
    background-color: transparent;
    border: 1px solid $gray-800;
    // font-weight: $font-weight-bold;
    color: $gray-800;
    &:hover {
      background-color: $secondary;
      color: $white;
    }
  }

  &.btn-outline-danger {
    background-color: transparent;
    border: 1px solid $danger;
    font-weight: $font-weight-bold;
    color: $danger;
    &:hover {
      background-color: $danger;
      color: $white;
    }
  }

  &.btn-link {
    padding-bottom: 0.25rem;
    color: $iron-gray;
    // @extend .hover-underline;

    &::before {
      background-color: $iron-gray;
    }

    &:hover {
      color: darken($iron-gray, 10%);
    }
  }
}
