// ========================================================================================================================================
// Forms - Custom
// ========================================================================================================================================

// ==========================================================================
// Form Check
// ==========================================================================

.form-check {
  // Radio
  .form-check-input[type="radio"],
  .form-check-input[type="checkbox"] {
    background: $gray-20;
    border-color: $gray-100;
    &:disabled {
      background-color: #e9e9e9 !important;
      border-color: $gray-300 !important;
    }

    &:checked {
      background-color: $black;
      border-color: $primary;
    }
  }

  .form-check-label {
    width: 100%;
    img {
      margin-right: 0.5rem;
      width: 75px;

      @include media-breakpoint-up(md) {
        width: 100px;
      }
    }
    .form-label-subtext.text-danger {
      @include font-size(0.875rem);
      margin-left: auto;
    }
  }

  &.form-check-custom {
    &.default {
      border: 1px solid $pearl-river;
      cursor: pointer;
      padding: 1.5rem;

      .bg-radio {
        position: absolute;
        width: 5rem;
        height: 100%;
        top: 0;
        left: 0;

        @include media-breakpoint-down(md) {
          width: 3.5rem;
        }
      }

      .form-check-input[type="radio"] {
        background: $white;
        border-color: $gray-800;
        margin-left: 0rem;

        &:checked {
          background-color: $white;

          &:after {
            background: $gray-800;
            width: 0.75rem;
            height: 0.75rem;
            top: 40%;
            left: 40%;
          }
        }
      }

      .form-check-label {
        font-family: $headings-font-family;
        margin-left: 3rem;
        cursor: pointer;

        @include media-breakpoint-down(md) {
          font-size: 0.875rem;
          margin-left: 2rem;
        }

        .wrapper-label-custom-radio {
          display: flex;
          align-items: center;
          img {
            margin-right: 1rem;
            width: 1.5rem;
            @include media-breakpoint-up(md) {
              width: 2rem;
            }
          }

          .form-check-label-custom {
            display: flex;
            flex-direction: column;
            .form-label-subtext {
              font-family: $font-family-base;
              font-size: 0.875rem;
              font-weight: $font-weight-normal;
              color: $fossil-gray;
              @include media-breakpoint-down(md) {
                font-size: 0.75rem;
              }
            }
          }
        }
      }
    }

    &.light {
      background: rgba($pearl-river, 0.3);
      // border: 1px solid $pearl-river;
      cursor: pointer;
      padding: 1rem 1.5rem;

      @include media-breakpoint-down(sm) {
        padding: 0.5rem 0.75rem;
      }

      .form-check-input[type="radio"] {
        background: $white;
        border-color: $gray-800;
        margin-left: 0rem;
        margin-right: 1.5rem;

        &:checked {
          background-color: $white;

          &:after {
            background: $gray-800;
            width: 0.75rem;
            height: 0.75rem;
            top: 40%;
            left: 40%;
          }
        }
      }

      .form-check-label {
        font-family: $font-family-base;
        font-size: 1rem;
        margin-left: 3rem;
        cursor: pointer;
        width: calc(100% - 3rem);

        @include media-breakpoint-down(md) {
          font-size: 0.875rem;
          margin-left: 2.5rem;
        }

        .wrapper-label-custom-radio {
          display: flex;
          align-items: center;

          .form-check-label-custom {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .form-label-subtext {
              font-family: $headings-font-family;
              font-size: 1rem;
              font-weight: $font-weight-bold;

              @include media-breakpoint-down(md) {
                font-size: 0.75rem;
              }
            }
          }
        }
      }
    }
  }
}

// ==========================================================================
// Select - React Select
// ==========================================================================

.form-select {
  &.ic-left {
    &:before {
      font-family: "Anti Icons Regular", sans-serif; // Modify default icon
      content: varIcon($ai-search); // Modify default icon
    }
  }

  &.ic-right {
    &:after {
      font-family: "Anti Icons Regular", sans-serif; // Modify default icon
      content: varIcon($ai-circle); // Modify default icon
    }
  }

  // Replace React Select default chevron
  .select__control {
    .select__value-container {
      @include ic-right($icon: $ai-angle-down); // Modify default chevron

      &:after {
        opacity: 0.5; // Modify default chevron base opacity
      }

      .select__single-value {
        @include media-breakpoint-down(md) {
          max-width: 85%;
        }
      }
    }

    &.select__control--menu-is-open {
      .select__value-container {
        &:after {
          opacity: 1; // Modify default chevron focus opacity
        }
      }
    }
  }
}

// React select dropdown menu
.select__option {
  &.select__option--is-focused {
    background: rgba($primary, 0.1); // Modify menu hover color

    &:active {
      background: rgba($primary, 0.1); // Modify menu active color
    }
  }
}

// React select dropdown menu
.select__menu {
  box-shadow: $box-shadow-sm !important;
  border: 1px solid $gray-50;
  border-radius: 0 !important;

  @if $enable-rounded {
    border-radius: $input-border-radius !important;
  }

  .select__option {
    background-color: transparent;
    transition: $transition-base;
    &.select__option--is-focused {
      background: rgba($nude-porcelain, 0.1); // Modify menu hover color
      // &:active {
      //   background: rgba($primary, 0.1); // Modify menu active color
      // }
    }

    &.select__option--is-selected {
      background: $primary; // Modify menu active color
      color: $body-color;
    }
  }
}

// ==========================================================================
// Input - Layer 1
// ==========================================================================

.field {
  position: relative;

  label {
    @include text-caption-1;
    color: $iron-gray;
  }
}

// ==========================================================================
// Form Control / Select Wrapper - Layer 2
// ==========================================================================

.form-control-wrapper {
  &.ic-left {
    &:before {
      font-family: "Anti Icons Regular", sans-serif; // Modify default icon
      content: varIcon($ai-circle); // Modify default icon
    }
  }

  &.ic-right {
    &:after {
      font-family: "Anti Icons Regular", sans-serif; // Modify default icon
      content: varIcon($ai-circle); // Modify default icon
    }
  }

  &.adornment-end {
    input {
      padding-right: 1.5rem;
    }
  }

  &.adornment-start {
    input {
      padding-left: 1.5rem;
    }
  }

  .ico-password {
    font-size: 1rem;
    color: inherit;
    position: absolute;
    width: $input-icon-width;
    height: $input-height;
    top: 0;
    right: 0;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.input-adornment {
  position: absolute;
  bottom: 50%;

  &.adornment-end {
    right: 0.25rem;
    transform: translate(0, 50%);
  }

  &.adornment-start {
    left: 0.25rem;
    transform: translate(0, 50%);
  }
}

.form-control {
  &.errors {
    border: 1px solid #c1524b;
  }

  &.form-search {
    height: calc(1em + 1.375rem + 2px);

    &.dark {
      color: $white !important;
    }
  }
}

.error-text {
  font-size: 0.9rem;
  color: #c1524b;
}

// /* Toggle Switch
// -------------------------------------------------- */
.toggle-switch {
  position: relative;
  display: flex;
  width: fit-content;
  height: 34px;
  margin-bottom: 0 !important;
  align-items: center;
  justify-content: center;
  border: 1px solid $fossil-gray;
  border-radius: 34px;
  cursor: pointer;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  small {
    position: relative;
    pointer-events: none;
    white-space: nowrap;
    padding: 0 0.5rem 0 2rem;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

.slide-toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 34px;

  &::before {
    position: absolute;
    border-radius: 50%;
    content: "";
    height: 20px;
    width: 20px;
    left: 7px;
    bottom: 6px;
    background-color: $fossil-gray;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

input:checked + .slide-toggle {
  background-color: $fossil-gray;

  & + small {
    color: $white;
    padding: 0 2rem 0 0.5rem;
  }

  &::before {
    background-color: $white;
  }
}

input:checked + .slide-toggle:before {
  left: calc(100% - 24px);
}
