.sc-carts {
  padding-top: 1rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 3rem;
  }
  .title-page {
    font-weight: $font-weight-lighter;
    text-align: center;
    color: $iron-gray;
    font-size: 1.5rem;
    line-height: 29px;
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      font-size: 2rem;
      line-height: 38px;
      margin-bottom: 2.5rem;
    }
  }
  .wrapper-carts {
    .list-carts {
      display: grid;
      gap: 1rem;
      @include media-breakpoint-up(md) {
        gap: 1.5rem;
      }
      .bag-item {
        display: grid;
        border: 1px solid $pearl-river;
        grid-template-columns: 80px 1fr;
        gap: 1rem;
        padding: 1.5rem;
        @include media-breakpoint-up(md) {
          grid-template-columns: 146px 1fr;
          gap: 1.5rem;
          padding: 2rem;
        }
        .bag-item__content {
          display: flex;
          flex-direction: column;
          .bag-item__desc {
            display: grid;
            grid-template-columns: 1fr min-content;
            gap: 0.5rem;
            height: 100%;
            .title {
              font-weight: $font-weight-lighter;
              margin-bottom: 0;
              font-size: 1rem;
              line-height: 19px;
              @include media-breakpoint-up(md) {
                font-size: 1.5rem;
                line-height: 29px;
              }
            }
          }
        }
        .bag-item__qty,
        .bag-item__qty-md {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .wrapper-price {
            .price-label {
              font-weight: $font-weight-light;
              font-size: 0.75rem;
              line-height: 14px;
              margin-bottom: 0;
              color: $fossil-gray;
            }
            .price-value {
              font-weight: $font-weight-light;
              font-size: 1.125rem;
              line-height: 22px;
              margin-bottom: 0;
            }
          }
        }
        .bag-item__qty-md {
          display: none;
          @include media-breakpoint-up(md) {
            display: flex;
          }
        }
        .bag-item__qty {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 2;
          @include media-breakpoint-up(md) {
            display: none;
          }
        }
      }
    }
    .order-summary {
      padding: 1.5rem;
      border: 1px solid $pearl-river;
      @include media-breakpoint-up(md) {
        padding: 2rem;
      }
      .text-summary {
        font-weight: $font-weight-lighter;
        font-size: 1.5rem;
        line-height: 29px;
        margin-bottom: 1.5rem;
      }
      .detail-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        .desc-detail {
          font-weight: $font-weight-light;
          font-size: 1rem;
          line-height: 24px;
          margin-bottom: 0;
          color: rgba($body-color, 0.7);
        }
        .value-detail {
          font-weight: $font-weight-lighter;
          font-size: 1rem;
          line-height: 19px;
          margin-bottom: 0;
        }
      }
      .detail-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1.5rem;
        .subtotal {
          font-weight: $font-weight-light;
          font-size: 1.125rem;
          line-height: 22px;
          margin-bottom: 0;
        }
        .grand-total {
          font-weight: $font-weight-light;
          font-size: 1.125rem;
          line-height: 22px;
          margin-bottom: 0;
        }
      }
      .btn-primary {
        font-size: 1rem;
        line-height: 20px;
      }
    }
  }
}

// .sc-carts {
//   @include media-breakpoint-down(sm) {
//     padding-left: 0.675rem !important;
//     padding-right: 0.675rem !important;
//   }
//   .ail {
//     font-size: 2rem;
//     cursor: pointer;
//   }
//   .bag-item {
//     border-bottom: 1px solid #dadedc;
//     padding: 1.75rem 0;
//     width: 100%;
//     &:first-child {
//       @include media-breakpoint-down(sm) {
//         padding-top: 0;
//       }
//     }
//   }

//   .bag-item__desc {
//     display: flex;
//     justify-content: center;
//     flex-direction: column;
//     .title {
//       font-size: 1.25rem;
//       @include media-breakpoint-down(sm) {
//         @include text-body-3;
//         @include text-truncate-multiline(2);
//       }
//     }
//     .subtitle {
//       color: rgba($black, 0.7);
//     }
//     .subtotal {
//       color: rgba($gray-800, 0.7);
//       text-decoration: line-through;
//       font-size: 0.875rem;
//     }
//     .price {
//       @include media-breakpoint-down(sm) {
//         @include text-title-3-mobile;
//       }
//     }
//   }
//   .bag-item__content {
//     width: 100%;
//     .subtotal {
//       color: rgba($gray-800, 0.7);
//       text-decoration: line-through;
//       font-size: 0.875rem;
//     }
//     .form-control {
//       text-align: center;
//       height: auto;
//       width: 120px;
//       background-color: rgba($pearl-river, 0.3);
//       @include media-breakpoint-down(sm) {
//         height: 2rem;
//         width: 85px;
//         font-size: 1.5rem;
//         padding: 0.25rem;
//       }
//     }
//     .bag-item__footer-wrapper {
//       justify-content: space-between;
//       .price {
//         font-family: $headings-font-family;
//         font-size: 1.5rem;
//         font-weight: $font-weight-medium;
//       }
//       .bag-item-qty__adjust {
//         display: flex;
//         position: relative;

//         .form-control {
//           text-align: center;
//           height: auto;
//           width: 120px;
//           background-color: rgba($pearl-river, 0.3);
//         }

//         .icon-qty-min,
//         .icon-qty-plus {
//           position: absolute;
//           font-size: 1.5rem;
//           z-index: 1;
//           cursor: pointer;
//           top: 50%;
//           transform: translate(0, -50%);
//         }

//         .icon-qty-min {
//           left: 1rem;
//         }

//         .icon-qty-plus {
//           right: 1rem;
//         }
//       }
//     }
//   }
//   .btn {
//     &.btn-outline-secondary,
//     &.btn-outline-danger {
//       @include media-breakpoint-down(sm) {
//         padding: 0px 10px;
//       }
//     }
//     &.btn-outline-danger {
//       @include media-breakpoint-down(sm) {
//         height: 2rem;
//       }
//     }
//   }
// }

// .order-summary {
//   background-color: $gray-50;
//   .text-summary {
//     @include text-title-2;
//     @include media-breakpoint-down(sm) {
//       @include text-title-3-mobile;
//     }
//   }
//   .detail {
//     @include text-body-1;
//     padding: 0.5rem 0;
//     color: rgba($iron-gray, 0.7);
//     @include media-breakpoint-down(sm) {
//       @include text-body-3;
//     }
//     &.voucher {
//       color: $green;
//     }
//   }
//   .detail-total {
//     margin-top: 1.5rem;
//   }
//   .subtotal {
//     @include text-body-1;
//     @include media-breakpoint-down(sm) {
//       @include text-body-2;
//     }
//   }
// }

// .discount-wrapper {
//   cursor: pointer;
//   &.has-discount {
//     cursor: default;
//   }
//   .total-discount {
//     color: $iron-gray;
//     border: 1px solid $pearl-river;
//     background: $white;
//     width: 100%;
//     font-family: $headings-font-family;
//     font-size: 1rem;
//     font-weight: $font-weight-lighter;
//     margin-top: 1.5rem;
//     margin-bottom: 1rem;
//     padding: 1rem;
//   }
// }

// .sc-wishlist-cart {
//   .general-title-wrapper {
//     font-family: $headings-font-family;
//     align-items: center;
//     @include media-breakpoint-down(sm) {
//       margin-bottom: 1rem;
//     }
//     h1 {
//       margin-bottom: 0;
//       @include media-breakpoint-down(sm) {
//         font-size: 1.25rem;
//       }
//     }
//     .see-all-wish {
//       float: right;
//       font-size: 1.5rem;
//       font-weight: 500;
//       @include media-breakpoint-down(sm) {
//         font-size: 0.875rem;
//       }
//     }
//   }
//   hr {
//     @include media-breakpoint-down(sm) {
//       display: none;
//     }
//   }
// }
// .wishlist-content,
// .recent-content,
// .banner-content {
//   @include media-breakpoint-down(sm) {
//     overflow: hidden;
//   }
//   .slider {
//     .slick-prev {
//       left: 0;
//     }
//     .slick-next {
//       right: 0;
//     }
//   }
// }
