.sc-search-result {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 3rem;
  }
  .sort-wrapper {
    margin-bottom: 2rem;
    .sort-content-wrapper {
      display: flex;
      gap: 0.75rem;
      justify-content: space-between;
      @include media-breakpoint-up(md) {
        justify-content: flex-end;
        gap: 1rem;
      }
      .wrapper-select {
        display: flex;
        gap: 0.5rem;

        .wrapper-field-select {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            height: 1px;
            background: $body-color;
            width: 100%;
          }
        }

        .label-select {
          font-weight: $font-weight-light;
          font-size: 1rem;
          line-height: 19px;
          color: $iron-gray;
          margin-bottom: 0;
        }
        .custom-select-category {
          position: relative;
          outline: none;
          border: none;
          background-color: transparent;
          font-family: $headings-font-family;
          @extend .label-select;
        }
      }
    }
  }
  .card-product-list {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}
