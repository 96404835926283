// ========================================================================================================================================
// Navbar - Custom
// ========================================================================================================================================

.navbar {
  .navbar-top {
    * {
      font-size: 12px;
    }

    .col-left {
      margin-left: -0.75rem; // Set depending on hover style & padding

      @include media-breakpoint-up($navbar-expand-up) {
        margin-left: -0.75rem; // Set depending on hover style & padding
      }
    }

    .col-right {
      justify-content: flex-end;
      margin-right: -0.75rem; // Set depending on hover style & padding

      @include media-breakpoint-up($navbar-expand-up) {
        margin-right: -0.75rem; // Set depending on hover style & padding
      }
    }

    .col-center {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
    }

    .col-right,
    .col-left,
    .col-center {
      flex: 1 1 auto;
    }

    @include media-breakpoint-up(xl) {
      .col-right,
      .col-left {
        flex: 33%;
      }
    }
  }

  .navbar-main {
    .col-left {
      margin-left: -0.75rem; // Set depending on hover style & padding

      @include media-breakpoint-up($navbar-expand-up) {
        margin-left: -0.75rem; // Set depending on hover style & padding
      }
    }

    .col-right {
      margin-right: -0.75rem; // Set depending on hover style & padding

      @include media-breakpoint-up($navbar-expand-up) {
        margin-right: 0; // Set depending on hover style & padding
      }
    }
  }

  &.transparent.navbar-dark {
    @include media-breakpoint-down($navbar-expand-up) {
      .navbar-main {
        background: $dark;

        .col-left {
          .navbar-brand.absolute {
            z-index: auto;
          }
        }

        .col-right {
          width: 65%;
          justify-content: flex-end;

          .nav-item {
            color: $white;
            width: 4rem;

            &.nav-divider {
              width: 0.25rem;
              display: flex;
              align-items: center;
            }

            .nav-link {
              padding: 0.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              height: $navbar-main-height-mobile;
              color: $white;
            }

            .nav-icon {
              color: $white;
              z-index: auto;

              &.nav-toggler {
                z-index: auto;

                .icon-bar {
                  background: $dark;
                }

                &.inactive {
                  .icon-bar {
                    background: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.navbar-brand {
  padding-left: 0.75rem;
  width: 120px;

  @include media-breakpoint-up($navbar-expand-up) {
    width: 100px;
  }
}

// ========================================================================================================================================
// Mobile
// ========================================================================================================================================

// ==========================================================================
// Mobile - Navbar Main
// ==========================================================================

.navbar-main {
  z-index: 2;

  .col-left,
  .col-right {
    .nav-item {
      width: 3rem;
    }

    .nav-icon {
      // prettier-ignore
      .ais,
      .aib,
      .ail,
      .air,
      .icon {
        top: 1px;
      }

      i {
        font-size: 1.125rem;
      }
    }
  }
}

.menu-bg-search {
  z-index: 1;
  transition-delay: 0s;
}

.search-nav-mobile {
  padding: 1rem;
  width: 100%;
  background: $white;
  opacity: 0;
  visibility: hidden;
  transition-duration: $mobile-menu-duration;
  transition-delay: 0s;
  transition-timing-function: $mobile-menu-ease;

  &.show {
    z-index: 2;
    opacity: 1;
    visibility: visible;
    transition-duration: $mobile-menu-duration;
    transition-timing-function: $mobile-menu-ease;
  }

  @include media-breakpoint-down(sm) {
    display: none;

    &.show {
      display: block;
    }
  }
}

.nav-toggler {
  .icon-bar {
    width: 22px;
    border-radius: 2px;
    height: 2px; // Alt 1

    // height: 1px; // Alt 2
    & + .icon-bar {
      margin-top: 4px; // Alt 1
      // margin-top: 5px; // Alt 2
    }
  }
}

// ==========================================================================
// Mobile - Menu
// ==========================================================================

// Main
.mobile-menu {
  width: 100%;
  position: absolute;
  top: 0;
  overflow-x: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  .mobile-language-wrapper {
    height: $navbar-height-mobile;
    display: flex;
    align-items: center;

    // .nav-icon {
    //   color: $white;

    //   &.nav-toggler {
    //     .icon-bar {
    //       background: $dark;
    //     }
    //   }
    // }
    .nav-close {
      font-size: 1.5rem;
    }
  }

  .mobile-menu-content {
    padding: 0rem;
    overflow-y: auto;
    height: calc(100vh - 315px);

    .navbar-nav {
      width: 100%;

      .nav-item {
        overflow: hidden;

        .nav-link {
          font-size: 1.5rem;

          .air {
            float: right;
          }
        }
      }
    }
  }

  .mobile-menu-bottom {
    background: $gray-800;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;

    // position: fixed;
    // bottom: 0;
    // width: 100%;
    .navbar-nav {
      width: 100%;

      .nav-item {
        overflow: hidden;

        .nav-link {
          color: rgba($white, 0.8);
          font-size: 1rem;
        }
      }

      .divider {
        border-top: 1px solid $fossil-gray;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}

// Detail
.mobile-menu-detail {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1091;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 1.5rem;

  .mobile-language-wrapper {
    height: $navbar-height-mobile;
    display: flex;
    align-items: center;
  }

  .mobile-menu-content {
    padding: 0rem;

    .title {
      font-size: 1.5rem;
      text-transform: capitalize;
    }

    .subtitle {
      @include hover-underline;
      font-size: 0.875rem;
      text-transform: uppercase;
      margin-top: 1.5rem;
      margin-bottom: 0.25rem;
      cursor: pointer;
      width: fit-content;

      &:hover {
        color: $gray-800;
      }

      &::before {
        bottom: 0;
        background-color: $gray-800;
      }
    }

    .navbar-nav {
      width: 100%;

      .nav-item {
        overflow: hidden;

        .btn-link {
          font-size: 1rem;
          color: rgba($gray-800, 0.8);
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }
    }
  }

  .mobile-menu-bottom {
    background: $gray-800;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;

    .navbar-nav {
      width: 100%;

      .nav-item {
        overflow: hidden;

        .nav-link {
          color: rgba($white, 0.8);
          font-size: 1rem;
        }
      }

      .divider {
        border-top: 1px solid $fossil-gray;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}

// ========================================================================================================================================
// Desktop
// ========================================================================================================================================

// ==========================================================================
// Desktop - Navbar Top
// ==========================================================================

.navbar-top {
  .nav-item {
    &.nav-divider {
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.25rem;
    }
  }

  .nav-link {
    padding: 0 0.75rem;

    i {
      font-size: 1rem;
    }
  }
}

// ==========================================================================
// Desktop - Navbar Main
// ==========================================================================

.navbar-main {
  &.navbar-lg {
    .container {
      padding-left: 0;
      padding-right: 0;

      @include media-breakpoint-down(md) {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
      }
    }
  }

  .desktop-menu {
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .navbar-nav {
      height: 100%;
      align-items: center;
    }
    .nav-item {
      margin-right: 2rem;
      margin-left: 0;
      height: 100%;
      align-items: center;
      .nav-link {
        white-space: nowrap;
        @include hover-underline;
        @extend .px-0;
        &:hover {
          color: $body-color !important;
        }
        &::before {
          bottom: 0.625rem;
          height: 1px;
        }
        &.nav-link-collapse {
          &::before {
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 50%;
            transition-timing-function: ease;
          }
        }
      }

      &.nav-search {
        margin: 0;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        .field-underline {
          .form-control {
            color: $body-color;
            & ~ .el-underline {
              background-color: rgba($iron-gray, 0.5);
            }
            &:focus ~ .el-underline {
              height: 1px;
              background-color: rgba($iron-gray, 1);
            }
          }
        }
      }
    }
  }
}

// ========================================================================================================================================
// Search
// ========================================================================================================================================

.search-menu {
  background: transparent;
}

.form-search {
  .form-control {
    border-color: transparent;
    border-radius: 2rem;
    padding-left: 2.75rem;
    background: $white;
  }

  .form-search-icon {
    font-size: 1.25rem;
    left: 0.75rem;
  }

  .form-search-close {
    right: 0.7rem;
    background: $gray-100;
    border-radius: 2rem;
    font-size: 0.875rem;
    cursor: pointer;

    @include hover-focus-active {
      background: $gray-200;
    }
  }
}

// Effect (Expand)
.search-menu {
  &.expand {
    .form-group {
      @include media-breakpoint-up($navbar-expand-up) {
        right: 120px;
      }

      .form-search {
        width: 80px;
      }
    }

    &.hide {
      .form-search {
        width: 80px;
      }
    }

    &.show {
      .form-search {
        width: 100%;
      }

      @include media-breakpoint-up($navbar-expand-up) {
        .form-search {
          width: 400px;
        }
      }
    }
  }
}

// ==========================================================================
// Color Theme
// ==========================================================================

// prettier-ignore
.navbar {
  &.navbar-light {
    .navbar-brand {
      .logo-light {
        display: none;
      }

      .logo-dark {
        display: block;
      }
    }

    .nav-toggler {
      .icon-bar {
        background-color: $dark;
      }
    }

    .navbar-top {
      background: $gray-100;

      .nav-item {
        .nav-link {
          color: $primary;

          &:hover {
            color: darken($primary, 10%);
          }
        }
      }
    }

    .navbar-main {
      background: $white;

      .nav-item {
        .nav-toggler .icon-bar {
          background: $primary;

          &:hover {
            background: darken($primary, 10%);
          }
        }

        .nav-link,
        .nav-icon {
          color: $primary;

          &:hover {
            color: darken($primary, 10%);
          }
        }
      }
    }
  }

  &.navbar-dark {
    .navbar-brand {
      .logo-dark {
        display: none;
      }

      .logo-light {
        display: block;
      }
    }

    .navbar-top {
      background: $gray-800;

      .nav-item {
        .nav-link {
          color: $white;

          &:hover {
            color: $primary;
          }
        }
      }
    }

    .navbar-main {
      background: $white;

      .nav-item {
        .nav-toggler .icon-bar {
          background: $gray-800;

          &:hover {
            background: $gray-800;
          }
        }

        .nav-link,
        .nav-icon {
          color: $gray-800;
          font-size: .875rem;
          &:hover {
            color: darken($primary, 20%);
          }
        }
      }

      input {
        background-color: transparent;
        color: rgba(51, 51, 51, 0.5);

        &::placeholder {
          color: rgba(51, 51, 51, 0.5);
        }
      }


      .el-underline {
        background-color: rgba(51, 51, 51, 0.5);
      }

      .field-underline {
        &.dark {
          input {
            &::placeholder {
              color: rgba($white, 0.8);
              opacity: 1;
            }

            &:-ms-input-placeholder {
              color: rgba($white, 0.8);
            }

            &::-ms-input-placeholder {
              color: rgba($white, 0.8);
            }
          }

          .el-underline {
            background-color: rgba($white, 0.5);
          }

        }
      }
    }
  }

  &.transparent {
    .navbar-main {
      background: transparent;
    }
  }

  &.is-scrolled {
    .navbar-main {
      background: $white;

      .navbar-nav {
        .nav-item {
          .nav-link {
            &.text-light {
              color: $gray-800  !important;
            }

            &.nav-link-collapse {
              color: $body-color !important;
              
            }
          }
        }
      }
    }
  }
}

.mobile-menu {
  background: $pearl-river;
  z-index: 1031;

  .nav-item {
    .nav-link {
      color: rgba($gray-800, 0.8);

      &:hover {
        color: darken($gray-800, 15%);
      }
    }
  }
}

.mobile-menu-detail {
  background: $pearl-river;

  .nav-item {
    .nav-link {
      color: rgba($gray-800, 0.8);

      &:hover {
        color: darken($gray-800, 15%);
      }
    }
  }
}

.desktop-megamenu {
  // display: flex;
  // justify-content: space-between;
  // min-height: 621px;
  position: absolute;
  left: 0;
  top: 72px;
  background-color: #eaeceb;
  width: 100vw;
  overflow: hidden;

  .items-menu {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .title {
      font-size: 0.9rem;
    }

    .menu {
      // min-width: 15%;
      // flex: 15%;
    }
  }

  &.initial {
    opacity: 0;
    pointer-events: none;
  }

  &.active {
    animation: fadeInUp 0.5s ease-in-out both;
    pointer-events: all;
  }

  &.hide {
    animation: fadeOutUp 0.5s ease-in-out both;
    pointer-events: none;
  }

  .container {
    display: block;
    height: fit-content !important;
    padding: 2.5rem 1.25rem 0.5rem;

    ul li {
      list-style: none;
    }
  }

  .megamenu-nav-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 3rem;
    .megamenu-nav {
      padding: 0;
      width: 100%;
      flex: 0 0 16.667%;
      // max-width: 16.667%;
      max-width: fit-content;
      margin-bottom: 2rem;
      &.custom-col-0,
      &.custom-col-1 {
        flex: 0 0 16.667%;
        max-width: 16.667%;
      }
      &.custom-col-2 {
        flex: 0 0 40%;
        max-width: 40%;
      }
      &.custom-col-3 {
        flex: 0 0 60%;
        max-width: 60%;
      }
      &.custom-col-4 {
        flex: 0 0 80%;
        max-width: 80%;
      }
      &.custom-col-5 {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .title {
        @include hover-underline;
        font-weight: $font-weight-lighter;
        font-size: 1.5rem;
        line-height: 29px;
        cursor: pointer;
        width: fit-content;
        &:hover {
          color: $body-color;
        }
        &::before {
          background-color: $body-color;
          bottom: 0;
        }
        &.no-child {
          font-family: $font-family-base;
          font-size: 1rem;
          line-height: 24px;
        }
        &.active {
          &::before {
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 50%;
            transition-timing-function: ease;
          }
        }
      }

      .nav-item-list {
        padding: 0;
        display: grid;
        // grid-template-rows: auto auto auto;
        gap: 0.75rem;
        margin-top: 0.75rem;
        &.custom-col-1 {
          grid-template-columns: 1fr;
        }
        &.custom-col-2 {
          grid-template-columns: 1fr 1fr;
        }
        &.custom-col-3 {
          grid-template-columns: 1fr 1fr 1fr;
        }
        &.custom-col-4 {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        &.custom-col-5 {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
        li {
          margin-bottom: 0rem;

          &.nav-item {
            margin-right: 0;
            .btn {
              &::before {
                bottom: 0;
              }
              span {
                text-align: left;
                font-family: $font-family-base;
                font-weight: $font-weight-light;
                font-size: 1rem;
                line-height: 24px;
                color: rgba($body-color, 0.8);
              }
            }
          }

          &.active {
            .btn {
              &.hover-underline::before {
                transform: scale3d(1, 1, 1);
                transform-origin: 0% 50%;
                transition-timing-function: ease;
              }
            }
          }
        }
      }
    }
  }

  .d-flex {
    gap: 2.25rem;
  }

  .img-megamenu-right {
    position: absolute;
    width: 250px;
    right: 0;
    height: 100%;
    top: 0;
  }
}

.floating-bag-wrapper {
  position: relative;
  width: 100%;
  transition: $transition-base;
  opacity: 0;
  visibility: hidden;

  @include media-breakpoint-down(sm) {
    top: 72px;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .ai-times {
    cursor: pointer;
  }

  .floating-bag {
    position: absolute;
    background: #eaeceb;
    z-index: 1030;
    right: 0;
    top: -72px;
    padding: 2rem;
    width: 575px;

    @include media-breakpoint-down(sm) {
      padding: 1rem;
      width: 100%;
      height: calc(100vh - 72px);

      .floating-bag-list {
        max-height: 60vh;
      }

      .grand-total {
        margin-top: 2rem;
      }
    }
  }
}

.side-drawer-mobile {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobile-language-wrapper {
    height: $navbar-height-mobile;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 30px;
      height: auto;
    }
    .nav-close {
      font-size: 1.5rem;
    }
  }
  .drawer-bottom {
    background: $gray-800;
    padding-bottom: 1.5rem;
    .other-links {
      color: $white;
      &.with-border {
        border-bottom: 1px solid $fossil-gray;
      }
    }
  }
}
