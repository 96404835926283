.pagination-custom {
  .MuiPaginationItem-page {
    margin-bottom: 1rem;
    height: 2rem;
    min-width: 2rem;
    border-radius: 0;
    font-size: 1rem;
    font-weight: $font-weight-lighter;
    color: $iron-gray;
    &.Mui-selected {
      background-color: $iron-gray;
      color: $white;
      font-weight: $font-weight-medium;
      &:hover {
        background-color: darken($iron-gray, 10%);
      }
    }
    .MuiPaginationItem-icon {
      font-size: 1.5rem;
    }
  }
}
