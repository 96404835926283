.sc-history-details {
  padding-top: 1rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 3rem;
  }
  .title-page {
    font-weight: $font-weight-lighter;
    text-align: center;
    color: $iron-gray;
    font-size: 1.5rem;
    line-height: 29px;
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      font-size: 2rem;
      line-height: 38px;
      margin-bottom: 2.5rem;
    }
  }
  .wrapper-history-detail {
    display: grid;
    gap: 1.75rem;
    @include media-breakpoint-up(md) {
      grid-template-columns: 3fr 2fr;
    }
    .sc-order-history {
      border: 1px solid $pearl-river;
      height: fit-content;
      padding: 1.5rem;
      @include media-breakpoint-up(md) {
        padding: 2rem;
      }
      .content-header {
        display: grid;
        gap: 1rem;
        margin-bottom: 1.5rem;
        @include media-breakpoint-up(md) {
          grid-template-columns: 1fr 1fr;
        }
        .detail-item {
          &:last-child {
            @include media-breakpoint-up(md) {
              text-align: right;
            }
          }
          .order-label {
            font-weight: $font-weight-light;
            font-size: 0.75rem;
            line-height: 14px;
            color: $fossil-gray;
            margin-bottom: 0.5rem;
          }
          .order-value {
            font-weight: $font-weight-lighter;
            font-size: 1.25rem;
            line-height: 24px;
            margin-bottom: 0;
          }
          .date-label {
            color: rgba($body-color, 0.7);
            margin-right: 0.5rem;
          }
          .date-value {
            font-weight: $font-weight-light;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 0.5rem;
          }
        }
      }
      .order-list {
        display: grid;
        gap: 1.5rem;
        .order-item {
          display: grid;
          grid-template-columns: 80px 1fr;
          gap: 1rem;
          .wrapper-order-item__content {
            display: flex;
            flex-direction: column;
            .title-product {
              font-weight: $font-weight-lighter;
              font-size: 1.25rem;
              line-height: 24px;
              margin-bottom: 0.5rem;
            }
            .wrapper-order-item__price {
              display: grid;
              @include media-breakpoint-up(md) {
                grid-template-columns: 1fr auto;
                align-items: flex-end;
              }
              .left-text {
                font-weight: $font-weight-light;
                font-size: 0.875rem;
                line-height: 24px;
                margin-bottom: 0.5rem;
                color: rgba($body-color, 0.7);
              }
              .right-text {
                font-weight: $font-weight-light;
                font-size: 0.875rem;
                line-height: 17px;
                margin-bottom: 0.5rem;
                @include media-breakpoint-up(md) {
                  text-align: right;
                }
              }
            }
          }
        }
      }
      .shipped-info-wrapper {
        display: grid;
        gap: 0.5rem;
        .shipper-info-item {
          display: grid;
          grid-template-columns: 1fr 1fr;
          justify-content: space-between;
          @include media-breakpoint-up(md) {
            display: flex;
          }
          .info-label {
            font-weight: $font-weight-light;
            font-size: 1rem;
            line-height: 150%;
            margin-bottom: 0;
            color: rgba($body-color, 0.7);
          }
          .info-value {
            text-align: right;
            font-weight: $font-weight-lighter;
            font-size: 1rem;
            line-height: 150%;
            margin-bottom: 0;
          }
        }
      }
    }
    .sc-payment-detail {
      padding: 2rem;
      border: 1px solid $pearl-river;
      height: fit-content;
      .title-content {
        font-weight: $font-weight-lighter;
        font-size: 1.5rem;
        line-height: 29px;
        margin-bottom: 1.5rem;
      }
      .payment-info-list {
        display: grid;
        gap: 0.5rem;
        .payment-info-item {
          display: flex;
          justify-content: space-between;
          .info-label {
            font-weight: $font-weight-light;
            font-size: 1rem;
            line-height: 150%;
            margin-bottom: 0;
            color: rgba($body-color, 0.7);
          }
          .info-value {
            text-align: right;
            font-weight: $font-weight-lighter;
            font-size: 1rem;
            line-height: 150%;
            margin-bottom: 0;
          }
          .total-label,
          .total-value {
            font-weight: $font-weight-light;
            font-size: 1.125rem;
            line-height: 150%;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  //   .sc-order-history {
  //     padding: 0;
  //     @include media-breakpoint-down(sm) {
  //       padding-left: 1rem;
  //       padding-right: 1rem;
  //     }
  //     .history-box {
  //       background-color: $white;
  //       border: 1px solid $pearl-river;
  //       .text-title-2 {
  //         @include media-breakpoint-down(sm) {
  //           @include text-title-3-mobile;
  //           @include text-truncate-multiline(2);
  //         }
  //       }
  //       .mid {
  //         .box-border {
  //           display: flex;
  //           .img-wrapper {
  //             margin-right: 1rem;
  //             width: 80px;
  //             height: 110px;
  //             .img-product-history {
  //               width: 80px;
  //               height: 110px;
  //               object-fit: cover;
  //             }
  //           }
  //           .desc-wrapper {
  //             width: 100%;
  //             position: relative;
  //             @include media-breakpoint-down(sm) {
  //               width: calc(100% - 6rem);
  //             }
  //             .text-title-3 {
  //               @include text-truncate-multiline(2);
  //               @include media-breakpoint-down(sm) {
  //                 font-size: 1rem;
  //               }
  //             }
  //             .content-price {
  //               display: flex;
  //               justify-content: space-between;
  //               flex-wrap: wrap;
  //               @include media-breakpoint-down(sm) {
  //                 .pcs {
  //                   flex: 0 0 100%;
  //                   font-size: 0.75rem;
  //                 }
  //                 .subtotal {
  //                   flex: 0 0 100%;
  //                   text-align: right;
  //                 }
  //               }
  //               .subtotal {
  //                 position: absolute;
  //                 bottom: 0;
  //                 right: 0;
  //               }
  //             }
  //           }
  //         }
  //       }
  //       .bottom {
  //         .total-price {
  //           @include media-breakpoint-down(sm) {
  //             font-size: 1.125rem;
  //           }
  //         }
  //       }
  //     }
  //     .history-box {
  //       padding: 0.5rem;
  //       @include media-breakpoint-down(sm) {
  //         padding: 0;
  //         .box {
  //           padding: 1.5rem 1rem;
  //         }
  //         .text-title-3 {
  //           font-size: 1.125rem;
  //         }
  //       }
  //     }
  //   }
}
