// ========================================================================================================================================
// Breadcrumb - Custom
// ========================================================================================================================================

// Add custom style here

.breadcrumb {
  &.breadcrumb-light {
    .breadcrumb-item {
      font-size: 0.875rem;
      color: $body-color;
      font-weight: $font-weight-semibold;
      a {
        color: rgba($color: $body-color, $alpha: 0.7);
        font-weight: $font-weight-lighter;
      }
      + .breadcrumb-item {
        &:before {
          color: $body-color;
          font-weight: $font-weight-lighter;
        }
      }
      a {
        &:before {
          background-color: $body-color; // for .hover-underline
        }
      }
      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }
  }
}
