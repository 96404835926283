.sc-home-slider {
  position: relative;
  .banner-home {
    pointer-events: none;
  }
  .custom_dot {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 2rem;
    .dot {
      transition: 0.3s;
      width: 0.75rem;
      height: 0.75rem;
      cursor: pointer;
      border-radius: 50%;
      background: rgba($white, 0.3);
      &.active-dot {
        background: $white;
      }
    }
  }
}

.sc-home-sale {
  position: relative;

  // &::before {
  //   content: "";
  //   width: 100%;
  //   height: 250px;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   background: $nude-porcelain;
  // }

  .title-section {
    font-weight: $font-weight-light;
    font-size: 1.5rem;
    line-height: 29px;
    margin-bottom: 0.75rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 1.5rem;
    }
  }
  .sale-product-list {
    display: grid;
    gap: 8.75px;
    grid-template-columns: repeat(2, 1fr);
    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

.sc-home-featured {
  .btn.btn-link {
    @include text-link-1;
    padding-top: 0;

    @include media-breakpoint-down(sm) {
      @include text-title-5;
    }
  }

  .title-section {
    margin-bottom: 2.5rem;

    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
    }
  }
}

.sc-home-project-gallery {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 5rem;
  }
  &::before {
    content: "";
    width: 100%;
    position: absolute;
    height: 218px;
    left: 0;
    top: 0;
    background-color: rgba($pearl-river, 0.3);
    @include media-breakpoint-up(md) {
      max-width: 859px;
      height: 400px;
    }
  }
  .container {
    position: relative;
    .title-wrapper {
      @extend .flex-center-between;
      margin-bottom: 1rem;
      @include media-breakpoint-up(md) {
        display: block;
        margin-bottom: 0;
      }
      .title-section {
        font-weight: $font-weight-light;
        font-size: 1.5rem;
        line-height: 29px;
        color: $iron-gray;
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
          margin-bottom: 0.5rem;
        }
      }
      .btn-link {
        font-weight: $font-weight-light;
        font-size: 1rem;
        line-height: 19px;
        padding-top: 0;
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
          margin-bottom: 1rem;
        }
      }
    }

    .project-gallery-list {
      display: grid;
      gap: 9px;
      grid-template-columns: repeat(2, 1fr);
      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(4, 1fr);
        padding-left: 10%;
      }
      .project-gallery__item {
        position: relative;
        @include media-breakpoint-up(md) {
          &:nth-child(even) {
            top: -3rem;
          }
        }
        &:hover {
          .img-caption {
            .title-caption {
              transform: translateY(0);
            }
            .btn-view-projects {
              opacity: 1;
              visibility: visible;
              pointer-events: all;
            }
          }
        }
        .img-caption {
          position: absolute;
          left: 0.5rem;
          bottom: 0.5rem;
          width: calc(100% - 1rem);
          @include media-breakpoint-up(md) {
            width: calc(100% - 2rem);
            left: 1rem;
            bottom: 1rem;
          }
          @include media-breakpoint-up(lg) {
            width: calc(100% - 3rem);
            left: 1.5rem;
            bottom: 1.5rem;
          }
          .title-caption {
            font-weight: $font-weight-light;
            font-size: 1.25rem;
            margin-bottom: 0;
            color: $white;
            transition: all 0.4s $ease-cubic-bezier;
            @include media-breakpoint-up(lg) {
              transform: translateY(2rem);
              font-size: 2rem;
              line-height: 38px;
            }
          }
          .btn-view-projects {
            transition: all 0.4s $ease-cubic-bezier;
            font-family: $headings-font-family;
            color: $white;
            text-decoration: underline;
            font-size: 0.75rem;
            font-weight: $font-weight-lighter;
            @include media-breakpoint-up(lg) {
              opacity: 0;
              visibility: hidden;
              pointer-events: none;
              font-size: 1rem;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
}

.sc-home-recent {
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 3rem;
  }
  .title-section {
    font-weight: $font-weight-light;
    font-size: 1.5rem;
    line-height: 29px;
    margin-bottom: 0.75rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 1.5rem;
    }
  }
  .recent-list {
    display: grid;
    gap: 8.75px;
    grid-template-columns: repeat(2, 1fr);
    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  .home-recent-product {
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 3.5rem;
    }
    .slider .slick-list .slick-track {
      margin-left: 4.375px;
      margin-right: 4.375px;
      .slick-slide {
        padding-left: 4.375px;
        padding-right: 4.375px;
      }
    }
    .slick-arrow {
      position: absolute;
      top: -2.5rem;
      background-color: transparent;
      width: 2.5rem;
      height: 2.5rem;
      .wrapper-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        color: $gray-800;
        height: 100%;
      }

      &::before {
        color: $secondary;
      }
    }

    .slick-next {
      right: 0;
      &::before {
        content: none;
      }
    }

    .slick-prev {
      left: auto;
      right: 2.5rem;
      &::before {
        content: none;
      }
    }
  }
}

.sc-home-connect-us {
  background: url("../../../public/img/sample/background-1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .wrapper-title {
    width: 100%;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    @include media-breakpoint-up(md) {
      width: fit-content;
    }
    .title {
      font-weight: $font-weight-light;
      font-size: 1.5rem;
      line-height: 29px;
      margin-bottom: 1.5rem;
      text-align: center;
      @include media-breakpoint-up(md) {
        font-weight: $font-weight-medium;
        font-size: 2.5rem;
        line-height: 48px;
        margin-bottom: 22px;
      }
    }
    .btn-fluid {
      background-color: $nude-porcelain;
      border-color: $nude-porcelain;
    }
  }
  .img-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .right-col {
    z-index: 10000000000 !important;
  }

  .img-content {
    height: 18rem !important;
    width: 100%;

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: 10rem !important;
    }
  }

  .wrapper-content {
    display: flex;

    .content-connect {
      display: flex;
      gap: 1rem;
      flex-wrap: nowrap;

      @include media-breakpoint-down(sm) {
        margin-top: 1rem;
      }

      .img-content {
        height: 18rem !important;
        width: 100%;

        @include media-breakpoint-down(sm) {
          height: 10rem !important;
          width: 100%;
        }
      }
    }
  }
}
