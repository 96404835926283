.sc-product-details {
  padding-top: 1rem;
  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
  .product-stock,
  .product-qty {
    display: flex;
    align-items: center;
    gap: 1rem;

    & > h6 {
      width: 100px;
      margin-bottom: 0;
    }

    .text-success {
      font-weight: bold;
    }
  }

  .row-content {
    margin-bottom: 1.5rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 4.5rem;
    }
    .product-preview {
      .product-review-slider {
        margin-bottom: 1rem;
        @include media-breakpoint-up(md) {
          margin-bottom: 3.5rem;
        }
      }

      .slick-arrow {
        position: absolute;
        top: unset;
        bottom: -4rem;
        background-color: transparent;
        width: 2.5rem;
        height: 2.5rem;
        .wrapper-arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.25rem;
          color: $gray-800;
          height: 100%;
        }

        &::before {
          color: $secondary;
        }
      }

      .slick-next {
        right: 0;
        &::before {
          content: none;
        }
      }

      .slick-prev {
        left: auto;
        right: 2.5rem;
        &::before {
          content: none;
        }
      }
    }
  }

  .wrapper-desc-product {
    .product-category {
      font-weight: $font-weight-light;
      font-size: 1rem;
      line-height: 18px;
      color: rgba($body-color, 0.5);
      margin-bottom: 1rem;
    }
    .product-title {
      font-weight: $font-weight-lighter;
      color: $iron-gray;
      font-size: 1.5rem;
      line-height: 29px;
      margin-bottom: 1rem;
      @include media-breakpoint-up(md) {
        font-size: 2rem;
        line-height: 38px;
      }
    }

    .discount {
      text-decoration: line-through;
      font-size: 0.875rem;
      font-weight: $font-weight-light;
    }
    .price {
      font-weight: $font-weight-light;
      font-size: 1.25rem;
      line-height: 28px;
      margin-bottom: 1rem;
    }

    .empty-stock {
      font-size: 1rem;
      line-height: 18px;
      color: #e17958;
      margin-bottom: 1rem;
    }
    .product-qty {
      margin-bottom: 1rem;
      .label-qty {
        font-weight: $font-weight-light;
        font-size: 1rem;
        line-height: 24px;
        margin-bottom: 0;
      }
      .stock-qty {
        font-weight: $font-weight-light;
        font-size: 1rem;
        line-height: 19px;
        color: $fossil-gray;
        margin-bottom: 0;
      }
    }
    .wrapper-btn {
      display: grid;
      gap: 1rem;
      margin-bottom: 1.5rem;
      @include media-breakpoint-up(md) {
        grid-template-columns: 1fr 1fr;
      }
      .btn {
        font-weight: $font-weight-light;
        font-size: 1rem;
        line-height: 20px;
      }
      .btn-outline-secondary {
        @include hover-focus-active {
          background-color: darken($white, 10%);
          color: $iron-gray;
        }
      }
    }

    .information-content {
      .title-information {
        font-weight: $font-weight-normal;
        font-size: 1.125rem;
        line-height: 22px;
        color: $iron-gray;
      }
      .dangerous-content {
        margin-bottom: 1.5rem;
        p {
          font-family: $headings-font-family;
          font-weight: $font-weight-lighter;
          font-size: 1rem;
          line-height: 28px;
        }
      }
    }
  }

  .recently-wrapper {
    .title-recently {
      font-weight: $font-weight-light;
      font-size: 1.5rem;
      line-height: 29px;
      margin-bottom: 0;
    }
    .recently-list {
      display: grid;
      gap: 8px;
      grid-template-columns: repeat(2, 1fr);
      @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(5, 1fr);
      }
    }
    .slick-arrow {
      position: absolute;
      top: -2.5rem;
      background-color: transparent;
      width: 2.5rem;
      height: 2.5rem;
      .wrapper-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        color: $gray-800;
        height: 100%;
      }

      &::before {
        color: $secondary;
      }
    }

    .slick-next {
      right: 0;
      &::before {
        content: none;
      }
    }

    .slick-prev {
      left: auto;
      right: 2.5rem;
      &::before {
        content: none;
      }
    }
  }
  .product-thumbnails {
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0.75rem;
    @include media-breakpoint-down(sm) {
      margin-bottom: 2rem;
    }
    .counting-thumbnails {
      position: absolute;
      left: 0;
      top: -3rem;
      h6 {
        font-weight: $font-weight-lighter;
        font-size: 1rem;
        line-height: 28px;
        color: $iron-gray;
        margin-bottom: 0;
      }
    }
    .product-thumbnails__item {
      opacity: 0.5;
      cursor: pointer;
      &.active {
        opacity: 1;
        cursor: default;
        .product-thumbnails__img {
          border: 1px solid $fossil-gray;
        }
      }
    }
  }
}
