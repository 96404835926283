.toast-wrapper {
  display: grid;
  grid-template-columns: 2rem 1fr 1.5rem;
  align-items: center;
  gap: 1rem;
  background-color: transparent;
  border: 1px solid $pearl-river;
  padding: 2rem;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: $transition-base;
  position: absolute;
  top: $navbar-height-mobile;
  z-index: 3;
  width: 100%;
  @include media-breakpoint-up($navbar-expand-up) {
    top: $navbar-height-desktop;
    min-height: $navbar-height-desktop / 2;
    max-width: 482px;
  }
  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    // z-index: 3;
  }
  &.toast-success {
    background-color: $white;
    border: 1px solid $pearl-river;
    color: $body-color;
  }
  &.toast-error {
    background-color: $light-pink;
    border: 1px solid $red-error;
    color: $red-error;
  }
  .btn-close {
    @extend .flex-center;
    .ai-times {
      font-size: 1.5rem;
    }
  }
}
