.sc-product-category {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
  .container.banner {
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .banner-category {
    margin-bottom: 2rem;
    position: relative;
    .banner-caption {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      text-align: center;
      .text-hero {
        // color: $body-color;
        color: $white;
        @include media-breakpoint-down(sm) {
          @include text-title-2;
        }
      }
      .text-title-2 {
        // color: $body-color;
        color: $white;
      }
    }
  }

  .product-category {
    padding-top: 0;
    padding-bottom: 5rem;
    .title {
      font-weight: $font-weight-lighter;
      text-align: center;
      color: $iron-gray;
      font-size: 1.5rem;
      line-height: 29px;
      margin-bottom: 1rem;
      @include media-breakpoint-up(md) {
        font-size: 2rem;
        line-height: 38px;
        margin-bottom: 0;
      }
    }
    .sort-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      margin-bottom: 2rem;
      @include media-breakpoint-down(sm) {
        display: block;
      }
      .text-title-2 {
        @include media-breakpoint-down(sm) {
          @include text-title-5;
          margin-bottom: 0.75rem;
        }
      }
      .sort-content-wrapper {
        display: flex;
        gap: 0.75rem;
        justify-content: space-between;
        @include media-breakpoint-up(md) {
          justify-content: flex-end;
          gap: 1rem;
        }
        .wrapper-select {
          display: flex;
          gap: 0.5rem;

          .wrapper-field-select {
            position: relative;
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              right: 0;
              height: 1px;
              background: $body-color;
              width: 100%;
            }
          }

          .label-select {
            font-weight: $font-weight-light;
            font-size: 1rem;
            line-height: 19px;
            color: $iron-gray;
            margin-bottom: 0;
          }
          .custom-select-category {
            position: relative;
            outline: none;
            border: none;
            background-color: transparent;
            font-family: $headings-font-family;
            @extend .label-select;
          }
        }
      }
    }
    .card-product-list {
      display: grid;
      gap: 8px;
      grid-template-columns: repeat(2, 1fr);
      @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
}
