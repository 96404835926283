.sc-contact {
  padding-top: 1rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 3rem;
  }
  .title-page {
    font-weight: $font-weight-lighter;
    text-align: center;
    color: $iron-gray;
    font-size: 1.5rem;
    line-height: 29px;
    margin-bottom: 0.5rem;
    @include media-breakpoint-up(md) {
      font-size: 2rem;
      line-height: 38px;
    }
  }
  .subtitle-page {
    font-weight: $font-weight-light;
    font-size: 0.875rem;
    line-height: 16px;
    text-align: center;
    color: rgba($iron-gray, 0.7);
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      font-size: 1rem;
      line-height: 19px;
      margin-bottom: 2.5rem;
    }
    .btn-link {
      font-family: $font-family-base;
      font-size: 0.875rem;
      line-height: 16px;
      padding-bottom: 0;
      &::before {
        bottom: 0;
      }
    }
  }
  .contact-us-form {
    padding: 1.5rem;
    border: 1px solid $pearl-river;
    @include media-breakpoint-up(md) {
      padding: 2rem;
    }
  }
}
