// ========================================================================================================================================
// Slider - Custom
// ========================================================================================================================================

// Add custom styles here
.connect-with-us {

   .slick-prev,
   .slick-next {
      border-radius: 50%;
   }

   .slick-next {
      right: 0rem;
   }

   .slick-prev {
      left: -0.8rem;
   }

}