// ========================================================================================================================================
// Footer - Custom
// ========================================================================================================================================

footer {
  background-color: $iron-gray;

  h5 {
    @extend .h6;
  }
}

.footer-bottom {
  background-color: $gray-800;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  .col-right {
    display: flex;
    justify-content: flex-end;
  }

  @include media-breakpoint-down(sm) {
    min-height: 70px;
    height: 100%;
    @include text-caption-mobile;

    .row {
      // padding: rem;
      flex-direction: column-reverse;

      .col-left {
        text-align: center;
      }

      .col-right {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.footer-top {
  border-bottom: 1px solid $white;
  .logo-brand {
    max-width: 240px;
  }
  h2 {
    border-bottom: 1px solid $white;
    font-family: $gillsans-font-family;
    font-weight: $font-weight-medium;
    font-size: 2rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.75rem;
    }
  }
}

.footer-main {
  padding-top: 1.5rem;
  padding-bottom: 3rem;

  @include media-breakpoint-down(sm) {
    padding-top: 0rem;
    padding-bottom: 0rem;

    .col-footer {
      margin: 0;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      border-bottom: 1px solid rgba($white, 0.3);

      &:last-child {
        margin: 0;
        padding-bottom: 1.25rem;
        border-bottom: none;
      }
    }
  }

  span,
  p,
  a {
    font-size: 1rem;
    font-weight: 400;
  }
}

.footer-contact {
  .footer-contact__item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    @include text-body-1;

    @include media-breakpoint-down(sm) {
      @include text-body-3;
    }

    a {
      margin: 0;
    }
  }
}

.footer-newsletter {
  input {
    background-color: transparent;
  }
}

.footer-menu {
  .navbar-nav {
    .nav-link {
      font-size: 1rem;
      text-transform: capitalize;
      @extend .text-white;
      &.social-link {
        text-transform: none;
      }
    }
  }
}
