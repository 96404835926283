// Custom Select QTY
.product-qty-adjustment {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  .btn-qty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    transition: $transition-base;
    border-radius: 3px;
    background: $white;
    font-size: 2rem;
    font-family: $headings-font-family;
    font-weight: $font-weight-lighter;
    &:hover {
      background: rgba($pearl-river, 0.3);
    }
    &.disabled {
      cursor: default;
      color: rgba($black, 0.2);
      background: $white;
    }
  }
  .wrapper-select-qty {
    position: relative;
    .wrapper-select-qty__label {
      width: 55px;
      height: 32px;
      background: rgba($pearl-river, 0.3);
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .wrapper-select-qty__options {
      visibility: none;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      transition: $transition-base;
      width: 100px;
      max-height: 314px;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: $white;
      border: 1px solid $body-color;
      z-index: 9;
      &::-webkit-scrollbar {
        width: 2px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: rgba($primary, 0.5);
        border-radius: 100px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $primary;
        border-radius: 100px;
      }

      &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
      .wrapper-select-qty__options-list {
        display: grid;
        .wrapper-select-qty__options-item {
          padding: 0 1rem;
          text-align: center;
          &:hover {
            background-color: rgba($primary, 0.4);
          }
          &.active {
            background-color: $primary;
          }
          .wrapper-select-qty__options-item-label {
            font-weight: $font-weight-lighter;
            font-size: 1rem;
            line-height: 180%;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
