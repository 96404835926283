.sc-privacy {
  padding-top: 1rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 3rem;
  }
  .title-page {
    font-weight: $font-weight-lighter;
    text-align: center;
    color: $iron-gray;
    font-size: 1.5rem;
    line-height: 29px;
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      font-size: 2rem;
      line-height: 38px;
      margin-bottom: 2.5rem;
    }
  }
  .wrapper-content {
    padding: 1.5rem;
    border: 1px solid $pearl-river;
    font-family: $headings-font-family;
    color: $iron-gray;
    font-weight: $font-weight-lighter;
    @include media-breakpoint-up(md) {
      padding: 2rem;
    }
    p {
      font-size: 1rem;
      line-height: 28px;
    }
  }
}
