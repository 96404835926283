/*
 * Antikode main.scss
 * Version - 3.0.1
 * Bootstrap - 4.5.0
 * 2021 Antikode
 */

// ========================================================================================================================================
// Import
// ========================================================================================================================================

// ==========================================================================
// Bootstrap Base & Custom Variables
// ==========================================================================

// Required functions / mixins used in variables config
@import "~bootstrap/scss/functions"; // DO NOT EDIT
@import "functions/strip-sass"; // e.g. strip unit in SASS
@import "mixins/fonts-custom"; // DO NOT EDIT

// Custom variables
// EDIT THESE VARIABLES
@import "variables/color"; // Set base color variables
@import "variables/options"; // Enable base rounded / shadows
@import "variables/spacing-grid";
@import "variables/border-shadow-modal"; // Set base border, box shadow, and modal variables
@import "variables/typography"; // Set custom fonts and base typography variables
@import "variables/buttons-forms"; // Set buttons / forms base padding and other variables
@import "variables/breadcrumbs";
@import "variables/transitions";
@import "variables/animations"; // Set general animation duration
@import "variables/navbar"; // Set navbar height, animation duration, and other variables

// Base styles
@import "base/bootstrap.scss"; // Bootstrap 4 base variables, mixins, and styles

// ==========================================================================
// Optional Plugins
// ==========================================================================

// Font Awesome 5.13.0
// @import "../plugins/fontawesome-pro/css/all.min.css";
// @import "../plugins/fontawesome-pro/scss/fontawesome.scss";

// Anti Icons 1.0.0
@import "../plugins/anti-icons/variables";
@import "../plugins/anti-icons/light/style.css";
@import "../plugins/anti-icons/regular/style.css";
@import "../plugins/anti-icons/solid/style.css";
@import "../plugins/anti-icons/brands/style.css";

// // Icomoon
@import "../plugins/icomoon/style.css";

// Slick Carousel
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// ==========================================================================
// Antikode Custom Mixins, Utilities, Overriders, and Base
// ==========================================================================

// Custom Mixins
@import "mixins/gradients"; // Gradients mixns
@import "mixins/icons"; // Font awesome mixins
@import "mixins/prefix"; // Vendor-prefixed CSS helper
@import "mixins/position"; // Shorthandizes position declarations
@import "mixins/text-truncate"; // Text truncate multiline

// Plugin Style Overrider
@import "overrider/datatable";
@import "overrider/select2";

// Utilities
@import "utilities/background"; // e.g. .bg-overlay
@import "utilities/buttons"; // e.g. .btn-block-mw-100px, .btn-block-mw-200px, etc.
@import "utilities/grid"; // e.g. .row-0, .row-1, .row-10px, .row-20px, etc.
@import "utilities/position"; // e.g. .center-both, etc.
@import "utilities/sizing"; // e.g. .h-50px, .w-md-100-px, etc.
@import "utilities/spacing"; // e.g. .py-main, .mb-sm-down-1, etc.
@import "utilities/text"; // e.g. .text-truncate-twoline, etc.

// SCSS base component
@import "base/alert";
@import "base/animations";
@import "base/background";
@import "base/dropdown";
@import "base/forms";
@import "base/nav";
@import "base/post";
@import "base/reboot";
@import "base/table";

// ==========================================================================
// Anti Components
// ==========================================================================

@import "../../components/anti/accordion/accordion";
@import "../../components/anti/accordion/accordion-custom";
@import "../../components/anti/breadcrumb/breadcrumb";
@import "../../components/anti/breadcrumb/breadcrumb-custom";
@import "../../components/anti/buttons/buttons";
@import "../../components/anti/buttons/buttons-custom";
@import "../../components/anti/card/card";
@import "../../components/anti/card/card-custom";
@import "../../components/anti/slider/slider";
@import "../../components/anti/slider/slider-custom";
@import "../../components/anti/slider-duo/slider-duo";
@import "../../components/anti/slider-duo/slider-duo-custom";
@import "../../components/anti/cover/cover";
@import "../../components/anti/cover/cover-custom";
@import "../../components/anti/cursor/cursor";
@import "../../components/anti/cursor/cursor-custom";
@import "../../components/anti/demo/demo";
@import "../../components/anti/download/download";
@import "../../components/anti/download/download-custom";
@import "../../components/anti/footer/footer";
@import "../../components/anti/footer/footer-custom";
@import "../../components/anti/feature/feature";
@import "../../components/anti/feature/feature-custom";
@import "../../components/anti/forms/forms";
@import "../../components/anti/forms/forms-custom";
@import "../../components/anti/image/image";
@import "../../components/anti/image/image-custom";
@import "../../components/anti/link/link";
@import "../../components/anti/link/link-custom";
@import "../../components/anti/loader/loader";
@import "../../components/anti/loader/loader-custom";
// @import "../../components/anti/map/map";
// @import "../../components/anti/map/map-custom";
@import "../../components/anti/modal/modal";
@import "../../components/anti/modal/modal-custom";
@import "../../components/anti/nav-bottom/nav-bottom";
@import "../../components/anti/nav-bottom/nav-bottom-custom";
@import "../../components/anti/navbar/navbar";
@import "../../components/anti/navbar/navbar-custom";
@import "../../components/anti/newsletter/newsletter";
@import "../../components/anti/newsletter/newsletter-custom";
@import "../../components/anti/pagination/pagination";
@import "../../components/anti/page-transition/page-transition";
@import "../../components/anti/page-transition/page-transition-custom";
@import "../../components/anti/post/post";
@import "../../components/anti/post/post-custom";
@import "../../components/anti/social/social";
@import "../../components/anti/social/social-custom";
@import "../../components/anti/stats/stats";
@import "../../components/anti/stats/stats-custom";
@import "../../components/anti/tabs/tabs";
@import "../../components/anti/tabs/tabs-custom";
@import "../../components/anti/type/type";
@import "../../components/anti/type/type-custom";
@import "../../components/anti/utils/utils";
@import "../../components/anti/utils/utils-custom";
@import "../../components/anti/video/video";

// ==========================================================================
// Global Components
// ==========================================================================
@import "../../components/global/card-product/card-product";
@import "../../components/global/floating-bag/floating-bag";
@import "../../components/global/toast/toast";
@import "../../components/global/floating-add-cart/floating-add-cart";
@import "../../components/global/custom-qty/custom-qty";

// ==========================================================================
// Gutenberg Components
// ==========================================================================

// REMOVE IF UNUSED
// @import "../../components/gutenberg/acf-label-w-value-list/label-w-value-list";
// @import "../../components/gutenberg/acf-download/download";
// @import "../../components/gutenberg/shortcode/post-list/post-list";
// @import "../../components/gutenberg/shortcode/project-list/project-list";

// ==========================================================================
// Pages Styles
// ==========================================================================

// Demo - REMOVE BEFORE DELIVERING
@import "pages/demo";

// Main Pages
@import "pages/404";
@import "pages/home";
@import "pages/about";
@import "pages/product-category";
@import "pages/product-details";
@import "pages/wishlist";
@import "pages/help";
@import "pages/shopping-bag";
@import "pages/account";
@import "pages/history-details";
@import "pages/confirm-payment";
@import "pages/contact-us";
@import "pages/payment";
@import "pages/checkout";
@import "pages/our-store";
@import "pages/result";
@import "pages/privacy-term";
@import "pages/carts";
@import "pages/reset-password";

// ========================================================================================================================================
// Custom Styles
// ========================================================================================================================================

// ==========================================================================
// General
// ==========================================================================

// Insert custom style here

// ==========================================================================
// NProgress
// ==========================================================================

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: $primary;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px $primary, 0 0 5px $primary;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

/* Common general layout for page */
.sc-general-layout {
  .text-body-1 {
    color: rgba($iron-gray, 0.7);

    @include media-breakpoint-down(sm) {
      @include text-body-2;
    }
  }

  .general-title {
    .text-head-3 {
      @include media-breakpoint-down(sm) {
        @include text-title-2;
        // padding-top: 1.5rem !important;
      }
    }
  }

  .wrapper-is-contact {
    display: flex;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    .contact-content {
      @include text-link-3;
      display: flex;
      gap: 0.25rem;

      @include media-breakpoint-down(sm) {
        @include text-link-2;
      }

      .btn.btn-link {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  hr {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.img-oke-wrapper {
  height: 20rem;
  width: 25rem;
  background-color: $black;

  .img-oke {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
}

// CONFIG
// $SPACER_XS: 0.5rem;
// $SPACER_SM: 0.75rem;
// $SPACER: 1rem;
// $SPACER_MD: 1.5rem;
// $SPACER_LG: 2rem;
// $BORDER_RADIU S: 3px;
// $PROGRESS_HEIGHT: 600px;
// $PROGRESS_LIST_HEIGHT: 75px;
// $PROGRESS_COUNTER_WIDTH: 26px;
// $LIST_ITEM_WIDTH: 100px;
// $PROGRESS_BG: #392A60;
// $BODY_BG: #2E1F47;
// $ICON_COLOR: #F3597D;

.progressContainer {
  position: relative;
  width: 300px;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  padding: 2rem;
  color: #fff;
  background: #392a60;
  @include box-shadow(0 0 15px rgba(0, 0, 0, 0.3));
}

.progresss {
  position: relative;
  padding: 0 1rem 0 3.5rem;
  margin: 2rem 0 0;
  list-style: none;
}

.progress__item {
  position: relative;
  min-height: 75px;
  counter-increment: list;
  padding-left: 0.5rem;

  &::before {
    content: "";
    position: absolute;
    left: -1.5rem;
    top: 33px;
    height: 60%;
    width: 1px;
    border-left: 1px solid #fff;
  }

  &::after {
    content: counter(list);
    position: absolute;
    top: 0;
    left: -2.5rem;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: transparent;
    color: #fff;
    font-weight: 400;
    font-size: 13px;
    line-height: 2rem;
    text-align: center;
    border: 1px solid #fff;
  }

  &:last-child {
    &:before {
      border: none;
    }
  }

  &.progress__item--completed {
    opacity: 0.6;
    color: #fff;

    &:after {
      content: "\2713";
      font-weight: 400;
      background: #fff;
      color: #392a60;
    }
  }
}

.progress__title {
  padding: 0.4rem 0 0.5rem;
  margin: 0;
  font-size: 1.5rem;
}

.progress__info {
  font-size: 13px;
}

.btn-back-wrapper {
  width: fit-content;
  margin-bottom: 1rem;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.loading-screen {
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  overflow: hidden;
}

.app-wrapper {
  position: relative;
}

.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  .img-lottie {
    height: 200px;
    width: 200px;
  }
  &.hilang {
    display: none !important;
  }
}
main.animated {
  animation-duration: 0.2s !important;
}
