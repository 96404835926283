// ========================================================================================================================================
// Image - Custom
// ========================================================================================================================================

.image {
  background: transparent; // default: $gray-50
}

.stock-blank-wrapper {
  .stock-blank-icon {
    height: 6rem;
    width: 6rem;
  }
}

