@font-face {
  font-family: "Anti Icons Solid";
  src: url("fonts/icomoon.eot?ldxoke");
  src: url("fonts/icomoon.eot?ldxoke#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?ldxoke") format("truetype"),
    url("fonts/icomoon.woff?ldxoke") format("woff"),
    url("fonts/icomoon.svg?ldxoke#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ais"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Anti Icons Solid", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ai-address-book:before {
  content: "\e900";
}
.ai-address-card:before {
  content: "\e901";
}
.ai-alarm-clock:before {
  content: "\e902";
}
.ai-album-collection:before {
  content: "\e903";
}
.ai-album:before {
  content: "\e904";
}
.ai-angle-down:before {
  content: "\e905";
}
.ai-angle-left:before {
  content: "\e906";
}
.ai-angle-right:before {
  content: "\e907";
}
.ai-angle-up:before {
  content: "\e908";
}
.ai-arrow-alt-circle-down:before {
  content: "\e909";
}
.ai-arrow-alt-circle-left:before {
  content: "\e90a";
}
.ai-arrow-alt-circle-right:before {
  content: "\e90b";
}
.ai-arrow-alt-circle-up:before {
  content: "\e90c";
}
.ai-arrow-circle-down:before {
  content: "\e90d";
}
.ai-arrow-circle-left:before {
  content: "\e90e";
}
.ai-arrow-circle-right:before {
  content: "\e90f";
}
.ai-arrow-circle-up:before {
  content: "\e910";
}
.ai-arrow-down:before {
  content: "\e911";
}
.ai-arrow-from-bottom:before {
  content: "\e912";
}
.ai-arrow-from-left:before {
  content: "\e913";
}
.ai-arrow-from-right:before {
  content: "\e914";
}
.ai-arrow-from-top:before {
  content: "\e915";
}
.ai-arrow-left:before {
  content: "\e916";
}
.ai-arrow-right:before {
  content: "\e917";
}
.ai-arrow-to-bottom:before {
  content: "\e918";
}
.ai-arrow-to-left:before {
  content: "\e919";
}
.ai-arrow-to-right:before {
  content: "\e91a";
}
.ai-arrow-to-top:before {
  content: "\e91b";
}
.ai-backspace:before {
  content: "\e91c";
}
.ai-ban:before {
  content: "\e91d";
}
.ai-barcode-alt:before {
  content: "\e91e";
}
.ai-barcode-read:before {
  content: "\e91f";
}
.ai-bars:before {
  content: "\e920";
}
.ai-bell:before {
  content: "\e921";
}
.ai-book-alt:before {
  content: "\e922";
}
.ai-book-open:before {
  content: "\e923";
}
.ai-bookmark:before {
  content: "\e924";
}
.ai-box-open:before {
  content: "\e925";
}
.ai-box:before {
  content: "\e926";
}
.ai-briefcase:before {
  content: "\e927";
}
.ai-building:before {
  content: "\e928";
}
.ai-bullhorn:before {
  content: "\e929";
}
.ai-bullseye:before {
  content: "\e92a";
}
.ai-calendar-day:before {
  content: "\e92b";
}
.ai-calendar:before {
  content: "\e92c";
}
.ai-camera:before {
  content: "\e92d";
}
.ai-caret-circle-down:before {
  content: "\e92e";
}
.ai-caret-circle-left:before {
  content: "\e92f";
}
.ai-caret-circle-right:before {
  content: "\e930";
}
.ai-caret-circle-up:before {
  content: "\e931";
}
.ai-cart-plus:before {
  content: "\e932";
}
.ai-chart-bar:before {
  content: "\e933";
}
.ai-chart-pie:before {
  content: "\e934";
}
.ai-check-circle:before {
  content: "\e935";
}
.ai-check:before {
  content: "\e936";
}
.ai-check-square:before {
  content: "\e937";
}
.ai-chevron-circle-down:before {
  content: "\e938";
}
.ai-chevron-circle-left:before {
  content: "\e939";
}
.ai-chevron-circle-right:before {
  content: "\e93a";
}
.ai-chevron-circle-up:before {
  content: "\e93b";
}
.ai-chevron-down:before {
  content: "\e93c";
}
.ai-chevron-left:before {
  content: "\e93d";
}
.ai-chevron-right:before {
  content: "\e93e";
}
.ai-chevron-up:before {
  content: "\e93f";
}
.ai-circle-notch:before {
  content: "\e940";
}
.ai-circle:before {
  content: "\e941";
}
.ai-clipboard:before {
  content: "\e942";
}
.ai-clock:before {
  content: "\e943";
}
.ai-clone:before {
  content: "\e944";
}
.ai-cog:before {
  content: "\e945";
}
.ai-coin:before {
  content: "\e946";
}
.ai-coins:before {
  content: "\e947";
}
.ai-comment-alt-dots:before {
  content: "\e948";
}
.ai-comment-alt-lines:before {
  content: "\e949";
}
.ai-comment-alt:before {
  content: "\e94a";
}
.ai-comment-dots:before {
  content: "\e94b";
}
.ai-comment:before {
  content: "\e94c";
}
.ai-comments-alt:before {
  content: "\e94d";
}
.ai-comments:before {
  content: "\e94e";
}
.ai-compass:before {
  content: "\e94f";
}
.ai-copy:before {
  content: "\e950";
}
.ai-credit-card-blank:before {
  content: "\e951";
}
.ai-cube:before {
  content: "\e952";
}
.ai-database:before {
  content: "\e953";
}
.ai-desktop:before {
  content: "\e954";
}
.ai-dice-d6:before {
  content: "\e955";
}
.ai-dollar-sign:before {
  content: "\e956";
}
.ai-download:before {
  content: "\e957";
}
.ai-ellipsis-h:before {
  content: "\e958";
}
.ai-ellipsis-v:before {
  content: "\e959";
}
.ai-envelope:before {
  content: "\e95a";
}
.ai-exchange-alt:before {
  content: "\e95b";
}
.ai-exchange:before {
  content: "\e95c";
}
.ai-exclamation-circle:before {
  content: "\e95d";
}
.ai-exclamation:before {
  content: "\e95e";
}
.ai-exclamation-square:before {
  content: "\e95f";
}
.ai-exclamation-triangle:before {
  content: "\e960";
}
.ai-expand:before {
  content: "\e961";
}
.ai-external-link-alt:before {
  content: "\e962";
}
.ai-external-link:before {
  content: "\e963";
}
.ai-external-link-square-alt:before {
  content: "\e964";
}
.ai-external-link-square:before {
  content: "\e965";
}
.ai-eye-slash:before {
  content: "\e966";
}
.ai-eye:before {
  content: "\e967";
}
.ai-file-chart-line:before {
  content: "\e968";
}
.ai-file-chart-pie:before {
  content: "\e969";
}
.ai-file-image:before {
  content: "\e96a";
}
.ai-file:before {
  content: "\e96b";
}
.ai-filter:before {
  content: "\e96c";
}
.ai-fingerprint:before {
  content: "\e96d";
}
.ai-flag-alt:before {
  content: "\e96e";
}
.ai-flask:before {
  content: "\e96f";
}
.ai-folder:before {
  content: "\e970";
}
.ai-frown-open:before {
  content: "\e971";
}
.ai-frown:before {
  content: "\e972";
}
.ai-gem:before {
  content: "\e973";
}
.ai-genderless:before {
  content: "\e974";
}
.ai-gift:before {
  content: "\e975";
}
.ai-globe-africa:before {
  content: "\e976";
}
.ai-globe-americas:before {
  content: "\e977";
}
.ai-globe-asia:before {
  content: "\e978";
}
.ai-globe-europe:before {
  content: "\e979";
}
.ai-globe:before {
  content: "\e97a";
}
.ai-grin-alt:before {
  content: "\e97b";
}
.ai-grin:before {
  content: "\e97c";
}
.ai-grip-lines:before {
  content: "\e97d";
}
.ai-grip-lines-vertical:before {
  content: "\e97e";
}
.ai-heart-circle:before {
  content: "\e97f";
}
.ai-heart:before {
  content: "\e980";
}
.ai-history:before {
  content: "\e981";
}
.ai-home-alt:before {
  content: "\e982";
}
.ai-home-lg-alt:before {
  content: "\e983";
}
.ai-id-badge:before {
  content: "\e984";
}
.ai-id-card:before {
  content: "\e985";
}
.ai-image:before {
  content: "\e986";
}
.ai-info-circle:before {
  content: "\e987";
}
.ai-info:before {
  content: "\e988";
}
.ai-info-square:before {
  content: "\e989";
}
.ai-landmark:before {
  content: "\e98a";
}
.ai-laptop:before {
  content: "\e98b";
}
.ai-list:before {
  content: "\e98c";
}
.ai-list-ul:before {
  content: "\e98d";
}
.ai-location-arrow:before {
  content: "\e98e";
}
.ai-lock:before {
  content: "\e98f";
}
.ai-long-arrow-alt-down:before {
  content: "\e990";
}
.ai-long-arrow-alt-left:before {
  content: "\e991";
}
.ai-long-arrow-alt-right:before {
  content: "\e992";
}
.ai-long-arrow-alt-up:before {
  content: "\e993";
}
.ai-long-arrow-down:before {
  content: "\e994";
}
.ai-long-arrow-left:before {
  content: "\e995";
}
.ai-long-arrow-right:before {
  content: "\e996";
}
.ai-long-arrow-up:before {
  content: "\e997";
}
.ai-map-marked-alt:before {
  content: "\e998";
}
.ai-map-marker-alt:before {
  content: "\e999";
}
.ai-map:before {
  content: "\e99a";
}
.ai-medal:before {
  content: "\e99b";
}
.ai-meh:before {
  content: "\e99c";
}
.ai-microphone-slash:before {
  content: "\e99d";
}
.ai-microphone:before {
  content: "\e99e";
}
.ai-minus-circle:before {
  content: "\e99f";
}
.ai-minus:before {
  content: "\e9a0";
}
.ai-minus-square:before {
  content: "\e9a1";
}
.ai-mobile-android-alt:before {
  content: "\e9a2";
}
.ai-mobile-android:before {
  content: "\e9a3";
}
.ai-money-bill:before {
  content: "\e9a4";
}
.ai-money-bill-wave:before {
  content: "\e9a5";
}
.ai-money-check-alt:before {
  content: "\e9a6";
}
.ai-moon:before {
  content: "\e9a7";
}
.ai-mouse-pointer:before {
  content: "\e9a8";
}
.ai-music-slash:before {
  content: "\e9a9";
}
.ai-music:before {
  content: "\e9aa";
}
.ai-newspaper:before {
  content: "\e9ab";
}
.ai-paper-plane:before {
  content: "\e9ac";
}
.ai-pen:before {
  content: "\e9ad";
}
.ai-phone-alt:before {
  content: "\e9ae";
}
.ai-phone-laptop:before {
  content: "\e9af";
}
.ai-phone-office:before {
  content: "\e9b0";
}
.ai-plane:before {
  content: "\e9b1";
}
.ai-play-circle:before {
  content: "\e9b2";
}
.ai-play:before {
  content: "\e9b3";
}
.ai-plus-circle:before {
  content: "\e9b4";
}
.ai-plus:before {
  content: "\e9b5";
}
.ai-plus-square:before {
  content: "\e9b6";
}
.ai-poll:before {
  content: "\e9b7";
}
.ai-portrait:before {
  content: "\e9b8";
}
.ai-power-off:before {
  content: "\e9b9";
}
.ai-qrcode:before {
  content: "\e9ba";
}
.ai-question-circle:before {
  content: "\e9bb";
}
.ai-question:before {
  content: "\e9bc";
}
.ai-question-square:before {
  content: "\e9bd";
}
.ai-receipt:before {
  content: "\e9be";
}
.ai-redo-alt:before {
  content: "\e9bf";
}
.ai-redo:before {
  content: "\e9c0";
}
.ai-repeat-alt:before {
  content: "\e9c1";
}
.ai-search:before {
  content: "\e9c2";
}
.ai-share-alt:before {
  content: "\e9c3";
}
.ai-share:before {
  content: "\e9c4";
}
.ai-shopping-bag:before {
  content: "\e9c5";
}
.ai-shopping-cart:before {
  content: "\e9c6";
}
.ai-sign-in-alt:before {
  content: "\e9c7";
}
.ai-sign-in:before {
  content: "\e9c8";
}
.ai-sign-out-alt:before {
  content: "\e9c9";
}
.ai-sign-out:before {
  content: "\e9ca";
}
.ai-sliders-h:before {
  content: "\e9cb";
}
.ai-sliders-h-square:before {
  content: "\e9cc";
}
.ai-sliders-v:before {
  content: "\e9cd";
}
.ai-sliders-v-square:before {
  content: "\e9ce";
}
.ai-smile:before {
  content: "\e9cf";
}
.ai-sort-alt:before {
  content: "\e9d0";
}
.ai-sort-amount-down:before {
  content: "\e9d1";
}
.ai-sort-amount-up:before {
  content: "\e9d2";
}
.ai-sort-down:before {
  content: "\e9d3";
}
.ai-sort:before {
  content: "\e9d4";
}
.ai-sort-up:before {
  content: "\e9d5";
}
.ai-spinner-third:before {
  content: "\e9d6";
}
.ai-square:before {
  content: "\e9d7";
}
.ai-stop-circle:before {
  content: "\e9d8";
}
.ai-stop:before {
  content: "\e9d9";
}
.ai-store-alt:before {
  content: "\e9da";
}
.ai-sync-alt:before {
  content: "\e9db";
}
.ai-sync:before {
  content: "\e9dc";
}
.ai-tablet-alt:before {
  content: "\e9dd";
}
.ai-tablet-android:before {
  content: "\e9de";
}
.ai-tachometer:before {
  content: "\e9df";
}
.ai-tag:before {
  content: "\e9e0";
}
.ai-tags:before {
  content: "\e9e1";
}
.ai-thumbs-down:before {
  content: "\e9e2";
}
.ai-thumbs-up:before {
  content: "\e9e3";
}
.ai-ticket-alt:before {
  content: "\e9e4";
}
.ai-ticket:before {
  content: "\e9e5";
}
.ai-times-circle:before {
  content: "\e9e6";
}
.ai-times:before {
  content: "\e9e7";
}
.ai-times-square:before {
  content: "\e9e8";
}
.ai-trash-alt:before {
  content: "\e9e9";
}
.ai-trash:before {
  content: "\e9ea";
}
.ai-trophy-alt:before {
  content: "\e9eb";
}
.ai-trophy:before {
  content: "\e9ec";
}
.ai-truck:before {
  content: "\e9ed";
}
.ai-tv-alt:before {
  content: "\e9ee";
}
.ai-unlock:before {
  content: "\e9ef";
}
.ai-upload:before {
  content: "\e9f0";
}
.ai-usd-circle:before {
  content: "\e9f1";
}
.ai-user-circle:before {
  content: "\e9f2";
}
.ai-user:before {
  content: "\e9f3";
}
.ai-video:before {
  content: "\e9f4";
}
.ai-volume-mute:before {
  content: "\e9f5";
}
.ai-volume-off:before {
  content: "\e9f6";
}
.ai-volume:before {
  content: "\e9f7";
}
