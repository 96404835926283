.sc-reset-password {
  padding-top: 1rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 3rem;
  }
  .title-page {
    font-weight: $font-weight-lighter;
    text-align: center;
    color: $iron-gray;
    font-size: 1.5rem;
    line-height: 29px;
    margin-bottom: 0.5rem;
    @include media-breakpoint-up(md) {
      font-size: 2rem;
      line-height: 38px;
    }
  }
  .subtitle-page {
    font-weight: $font-weight-light;
    font-size: 1rem;
    line-height: 19px;
    text-align: center;
    color: rgba($iron-gray, 0.7);
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 2.5rem;
    }
  }
  .new-password {
    border: 1px solid $pearl-river;
    padding: 1.5rem;
    @include media-breakpoint-up(md) {
      padding: 2rem;
    }
  }
}
