// ========================================================================================================================================
// Modal
// ========================================================================================================================================

// Add custom styles here
.modal-wrapper {
  .bg-gray {
    background-color: $gray-50;

    p {
      color: $gray-400;
    }
  }
}

.gray-content {
  background-color: #f3f3f3;
}

.scrolled {
  overflow-y: scroll;
}

.modal-auth {
  // background-color: $gray-50;
  .title {
    font-weight: $font-weight-light;
    font-size: 1.5rem;
    line-height: 29px;
    margin-bottom: 0.25rem;
  }
  .subtitle {
    font-size: 1rem;
    line-height: 18px;
    margin-bottom: 2.5rem;
    color: rgba($iron-gray, 0.7);
  }

  .forgot-text {
    text-decoration: underline;
    cursor: pointer;
  }

  .img-wrapper {
    position: relative;

    .title-modal {
      position: absolute;
      left: 50%;
      top: 50%;
      color: $white;
      transform: translate(-50%, -50%);
      font-family: $gillsans-font-family;
    }
  }

  .between-line-text {
    font-size: 1rem;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $gray-300;
    line-height: 0.1em;
    margin: 10px 0 30px;

    span {
      color: $gray-600;
      background-color: $gray-50;
    }
  }
}

.modal-promo {
  padding: 2rem;
  .modal-title {
    font-weight: $font-weight-light;
    font-size: 1.5rem;
    line-height: 29px;
  }

  .applied-wrapper {
    margin-top: 1.5rem;
  }
  .list-promo {
    .promo-item {
      &:not(.disable) {
        cursor: pointer;
      }
    }
  }
  .title-content {
    font-weight: $font-weight-light;
    font-size: 1rem;
    line-height: 19px;
    margin-bottom: 1.5rem;
  }
  .promo-item {
    background-color: $white;
    border: 1px solid $pearl-river;
    padding: 1rem;
    position: relative;
    margin-bottom: 1.5rem;
    &.disable {
      background-color: $gray-200;
      * {
        opacity: 0.5;
      }
    }
    .text-promo {
      font-weight: $font-weight-semibold;
      font-size: 1rem;
      line-height: 19px;
      margin-bottom: 0.5rem;
    }
    .subtext-promo {
      font-weight: $font-weight-light;
      font-size: 0.75rem;
      line-height: 14px;
      margin-bottom: 0;
      color: rgba($body-color, 0.7);
    }
    .btn-remove {
      position: absolute;
      top: 1rem;
      right: 1rem;
      color: $red;
      text-decoration: underline;
      cursor: pointer;
      font-weight: $font-weight-light;
      font-size: 14px;
      line-height: 16px;
    }
    .auto-apply {
      position: absolute;
      bottom: 0;
      right: 0;
      border: 1px solid $pearl-river;
      padding: 0.5rem;
    }
  }
}

.modal-account-address {
  .title-section {
    font-weight: $font-weight-light;
    font-size: 1.5rem;
    line-height: 29px;
    margin-bottom: 0;
  }
}
