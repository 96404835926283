.floating-bag-list {
  max-height: 300px;
  overflow-y: auto;

  .bag-item__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 345px;
    .subtotal {
      color: rgba($gray-800, 0.7);
      text-decoration: line-through;
      font-size: 0.875rem;
    }
    .btn-outline-danger {
      // padding: 0.675rem 1rem;
      &:hover {
        color: $white;
      }
    }
  }
  .bag-item {
    border-bottom: 1px solid #dadedc;
    padding: 1.25rem 0;

    &:last-child {
      border-color: transparent !important;
    }
  }
}

.bag-item-qty__adjust {
  display: flex;
  position: relative;

  .form-control {
    text-align: center;
    height: calc(1.25em + 1.875rem + 6px);
    background-color: $white;
    border-color: $white;
  }

  .icon-qty-min,
  .icon-qty-plus {
    position: absolute;
    font-size: 1.5rem;
    z-index: 1;
    cursor: pointer;
    top: 50%;
    transform: translate(0, -50%);
  }

  .icon-qty-min {
    left: 1rem;
  }

  .icon-qty-plus {
    right: 1rem;
  }
}

.floating-bag {
  .bag-item {
    border-bottom: 1px solid #dadedc;
    padding: 1.25rem 0;

    &:last-child {
      border-color: transparent !important;
    }
  }
  .floating-bag__empty {
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .bag-item-qty {
    .bag-item-qty__wrapper {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
      .bag-item-qty__adjust {
        display: grid;
        grid-template-columns: auto auto auto;
        // position: relative;
        // .btn {
        //   padding: 0;
        //   width: 3rem;
        //   height: 3rem;
        //   @include media-breakpoint-down(sm) {
        //     width: 2rem;
        //     height: 2rem;
        //   }
        // }
        .form-control {
          text-align: center;
          height: 3rem;
          background-color: $white;
          @include media-breakpoint-down(sm) {
            height: 2rem;
          }
        }

        .icon-qty-min,
        .icon-qty-plus {
          position: absolute;
          font-size: 1.5rem;
          z-index: 1;
          cursor: pointer;
          top: 50%;
          transform: translate(0, -50%);
        }

        .icon-qty-min {
          left: 1rem;
        }

        .icon-qty-plus {
          right: 1rem;
        }
      }
      .btn {
        padding: 0;
        width: 3rem;
        height: 3rem;
        @include media-breakpoint-down(sm) {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}
