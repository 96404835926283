// ░█▀▀█ ░█▀▀▀ ░█▀▄▀█ ░█▀▀▀█ ░█──░█ ░█▀▀▀ 　 ▀█▀ ░█▀▀▀ 　 ░█─░█ ░█▄─░█ ░█─░█ ░█▀▀▀█ ░█▀▀▀ ░█▀▀▄
// ░█▄▄▀ ░█▀▀▀ ░█░█░█ ░█──░█ ─░█░█─ ░█▀▀▀ 　 ░█─ ░█▀▀▀ 　 ░█─░█ ░█░█░█ ░█─░█ ─▀▀▀▄▄ ░█▀▀▀ ░█─░█
// ░█─░█ ░█▄▄▄ ░█──░█ ░█▄▄▄█ ──▀▄▀─ ░█▄▄▄ 　 ▄█▄ ░█─── 　 ─▀▄▄▀ ░█──▀█ ─▀▄▄▀ ░█▄▄▄█ ░█▄▄▄ ░█▄▄▀
// When starting real project, remove this along with the pages demo files.

// ========================================================================================================================================
// Demo
// ========================================================================================================================================

// ==========================================================================
// Components
// ==========================================================================

.bd-demo__buttons {
  section {
    @include media-breakpoint-down(md) {
      padding-bottom: 1rem;
    }
    .btn {
      margin-right: 0.5rem;
      @include media-breakpoint-down(md) {
        margin-bottom: 0.5rem;
      }
    }
    &#buttonsHoverEffects {
      .btn:not(.btn-link) {
        @include hover-blockRight;
      }
      .btn-link {
        @include hover-underline;
        &:before {
          bottom: 0.25rem; // underline effect position (default: 0)
        }
      }
    }
  }
}
