.floating-add-cart-wrapper {
  position: relative;
  width: 100%;
  transition: $transition-base;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  @include media-breakpoint-down(sm) {
    top: 72px;
  }
  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
  .ai-times {
    cursor: pointer;
  }

  .floating-add-cart {
    position: absolute;
    background: $white;
    z-index: 1030;
    right: 0;
    // top: -72px;
    padding: 2rem;
    border: 1px solid $pearl-river;
    width: 482px;
    @include media-breakpoint-down(sm) {
      padding: 1rem;
      width: 100%;
      .floating-bag-list {
        max-height: 60vh;
      }
      .grand-total {
        margin-top: 2rem;
      }
    }
    .wrapper-header {
      display: flex;
      margin-bottom: 1.5rem;
      .title-wrapper {
        width: 90%;
        display: flex;
        align-items: center;
        & > div {
          margin-right: 1rem;
          .ai-check-circle {
            font-size: 2rem;
          }
          .custom-checkmark {
            width: 2rem;
          }
        }
        h4 {
          font-weight: $font-weight-light;
          font-size: 1rem;
          line-height: 19px;
          margin-bottom: 0;
        }
      }
      .close-wrapper {
        width: 10%;
        text-align: right;
        .ai-times {
          font-size: 1.25rem;
        }
      }
    }
    .btn-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
    .btn-outline-secondary {
      color: $gray-800;
      &:hover {
        color: $white;
      }
    }
    .floating-bag-list {
      max-height: 300px;
      overflow-y: auto;

      .bag-item__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: unset;
        .btn-outline-danger {
          padding: 0.675rem 1rem;
        }
        .bag-item__desc {
          .subtotal {
            color: rgba($gray-800, 0.7);
            text-decoration: line-through;
            font-size: 0.875rem;
          }
          .col-md-9 {
            @include media-breakpoint-down(sm) {
              margin-bottom: 1rem;
            }
          }
          .col-md-3 {
            .text-price {
              font-family: $headings-font-family;
            }
          }
          .text-price {
            color: rgba($gray-800, 0.7);
            white-space: nowrap;
            font-family: $font-family-base;
          }
        }
      }
    }

    .bag-item {
      border-bottom: 1px solid #dadedc;
      padding: 1.25rem 0;

      &:last-child {
        border-color: transparent !important;
      }
    }

    .bag-item-qty__adjust {
      display: flex;
      position: relative;

      .form-control {
        text-align: center;
        height: calc(1.25em + 1.875rem + 2px);
        background-color: transparent;
      }

      .icon-qty-min,
      .icon-qty-plus {
        position: absolute;
        font-size: 1.5rem;
        z-index: 1;
        cursor: pointer;
        top: 50%;
        transform: translate(0, -50%);
      }

      .icon-qty-min {
        left: 1rem;
      }

      .icon-qty-plus {
        right: 1rem;
      }
    }
  }
}
