.sc-shopping-bag {
  @include media-breakpoint-down(sm) {
    padding-left: 0.675rem !important;
    padding-right: 0.675rem !important;
  }
  .ail {
    font-size: 2rem;
    cursor: pointer;
  }
  .bag-item {
    border-bottom: 1px solid #dadedc;
    padding: 1.75rem 0;
    width: 100%;
    &:first-child {
      @include media-breakpoint-down(sm) {
        padding-top: 0;
      }
    }
  }

  .bag-item__desc {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .title {
      font-size: 1.25rem;
    }
    .subtitle {
      color: rgba($black, 0.7);
    }
  }
  .bag-item__content {
    width: 100%;
    .bag-item__footer-wrapper {
      justify-content: space-between;
      .price {
        font-family: $headings-font-family;
        font-size: 1.5rem;
        font-weight: $font-weight-medium;
      }
      .bag-item-qty__adjust {
        display: flex;
        position: relative;

        .form-control {
          text-align: center;
          height: calc(1.25em + 1.875rem + 2px);
          background-color: transparent;
        }

        .icon-qty-min,
        .icon-qty-plus {
          position: absolute;
          font-size: 1.5rem;
          z-index: 1;
          cursor: pointer;
          top: 50%;
          transform: translate(0, -50%);
        }

        .icon-qty-min {
          left: 1rem;
        }

        .icon-qty-plus {
          right: 1rem;
        }
      }
    }
  }

  .order-summary {
    background-color: $gray-50;
    .text-summary {
      @include text-title-2;
      @include media-breakpoint-down(sm) {
        @include text-title-3-mobile;
      }
    }
    .detail {
      @include text-body-1;
      padding: 0.5rem 0;
      color: rgba($iron-gray, 0.7);
      @include media-breakpoint-down(sm) {
        @include text-body-3;
      }
      &.voucher {
        color: $green;
      }
    }
    .detail-total {
      margin-top: 1.5rem;
    }
    .subtotal {
      @include text-body-1;
      @include media-breakpoint-down(sm) {
        @include text-body-2;
      }
    }
  }

  .discount-wrapper {
    cursor: pointer;
    &.has-discount {
      cursor: default;
    }
    .total-discount {
      color: $iron-gray;
      border: 1px solid $pearl-river;
      background: $white;
      width: 100%;
      font-family: $headings-font-family;
      font-size: 1rem;
      font-weight: $font-weight-lighter;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      padding: 1rem;
    }
  }

  .shopping-bag-wishlist {
    .slick-disabled {
      display: none !important;
    }

    .slick-next,
    .slick-prev {
      background-color: $white;
      border: 1px solid $black;

      &::before {
        color: $black;
      }
    }
  }
}
