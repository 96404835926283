.sc-checkout {
  padding-top: 1rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 3rem;
  }
  .title-page {
    font-weight: $font-weight-lighter;
    text-align: center;
    color: $iron-gray;
    font-size: 1.5rem;
    line-height: 29px;
    margin-bottom: 0.5rem;
    @include media-breakpoint-up(md) {
      font-size: 2rem;
      line-height: 38px;
    }
  }
  .subtitle-page {
    font-weight: $font-weight-light;
    font-size: 1rem;
    line-height: 19px;
    text-align: center;
    color: rgba($iron-gray, 0.7);
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 2.5rem;
    }
  }

  .wrapper-checkout {
    .title-section {
      font-weight: $font-weight-light;
      font-size: 1.5rem;
      line-height: 29px;
      color: $iron-gray;
      margin-bottom: 1.5rem;
    }
    .sc-shipping-details {
      padding: 1.5rem;
      border: 1px solid $pearl-river;
      @include media-breakpoint-up(md) {
        padding: 2rem;
      }
      .form-check {
        .form-check-input[type="checkbox"] {
          border: 1.5px solid $fossil-gray;
          border-radius: 4px;
          &:checked {
            background-color: $iron-gray;
            &::after {
              font-size: 0.75rem;
              margin-left: -6px;
            }
          }
        }
      }
      .address-primary,
      .discount-wrapper {
        padding: 1.5rem;
        border: 1px solid $pearl-river;
        outline: none;
        position: relative;
        .label-address {
          font-weight: $font-weight-lighter;
          font-size: 1.25rem;
          line-height: 24px;
          margin-bottom: 0.5rem;
        }
        .address-value {
          font-weight: $font-weight-light;
          font-size: 1rem;
          line-height: 150%;
          color: rgba($body-color, 0.7);
          margin-bottom: 0;
        }
        .btn-choose {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          font-weight: $font-weight-light;
          font-size: 1rem;
          line-height: 19px;
          text-decoration: underline;
        }
      }
    }
    .sc-payment-method {
      padding: 1.5rem;
      border: 1px solid $pearl-river;
      margin-top: 1.5rem;
      @include media-breakpoint-up(md) {
        padding: 2rem;
      }
      .list-payment {
        display: grid;
        gap: 1.5rem;
        .radio-payment {
          transition: $transition-base;
          height: 82px;
          &.disabled {
            border: 1px solid #dadedc;
            background: #e9e9e9;
            cursor: default;
            .form-check-label {
              cursor: default !important;
            }
          }
          &.active {
            height: 184px;
            .wrapper-child-radio {
              opacity: 1;
              visibility: visible;
              pointer-events: all;
            }
          }
          .wrapper-child-radio {
            transition: $transition-base;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
          }
        }
      }
    }
    .discount-wrapper {
      padding: 1.5rem;
      border: 1px solid $pearl-river;
      &.no-disc {
        cursor: pointer;
      }
      .promo-title {
        font-weight: $font-weight-light;
        font-size: 1rem;
        line-height: 19px;
        margin-bottom: 0;
        display: grid;
        grid-template-columns: 2rem auto min-content;
        gap: 1rem;
        align-items: center;
        .wrapper-promo__content {
          display: grid;
          gap: 4px;
          .wrapper-promo__content-title {
            font-weight: $font-weight-light;
            font-size: 1rem;
            line-height: 19px;
            margin-bottom: 0;
          }
          .wrapper-promo__content-text {
            font-weight: $font-weight-light;
            font-size: 1rem;
            line-height: 19px;
            margin-bottom: 0;
            color: $fossil-gray;
          }
        }
        .btn-change {
          font-size: 0.875rem;
          line-height: 16px;
          margin-bottom: 0;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .order-summary {
      margin-top: 1.5rem;
      padding: 1.5rem;
      border: 1px solid $pearl-river;
      @include media-breakpoint-up(md) {
        padding: 2rem;
      }
      .detail-order-list {
        display: grid;
        gap: 1rem;
        .detail-order-item {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 1rem;
          .detail-order-item__product {
            font-weight: $font-weight-light;
            font-size: 1rem;
            line-height: 150%;
            margin-bottom: 0;
            color: rgba($body-color, 0.7);
          }
          .detail-order-item__price {
            font-weight: $font-weight-lighter;
            font-size: 1rem;
            line-height: 150%;
            margin-bottom: 0;
            text-align: right;
          }
          .subtotal {
            font-weight: $font-weight-light;
            font-size: 1.125rem;
            line-height: 150%;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  // .title-p {
  //   font-size: 1.5rem;
  //   font-weight: $font-weight-medium;
  // }

  // .address-primary {
  //   border: 1px solid $gray-100;
  // }

  // .order-summary {
  //   // background-color: $gray-50;

  //   .text-summary {
  //     @include text-title-2;

  //     @include media-breakpoint-down(sm) {
  //       @include text-title-3-mobile;
  //     }
  //   }

  //   .detail {
  //     @include text-body-1;
  //     padding: 0.5rem 0;
  //     color: rgba($iron-gray, 0.7);

  //     .detail-item {
  //       font-size: 1rem;
  //     }

  //     @include media-breakpoint-down(sm) {
  //       @include text-body-3;
  //     }

  //     &.voucher {
  //       color: $green;
  //     }
  //   }

  //   .detail-total {
  //     margin-top: 1.5rem;
  //   }

  //   .subtotal {
  //     @include text-body-1;

  //     @include media-breakpoint-down(sm) {
  //       @include text-body-2;
  //     }
  //   }
  // }

  // .sc-payment-method {
  //   background-color: #f3f3f3;

  //   @include media-breakpoint-down(sm) {
  //     margin-bottom: 1.5rem;
  //   }
  // }

  // .discount-wrapper {
  //   cursor: pointer;
  //   background: $iron-gray;

  //   &.has-discount {
  //     cursor: default;
  //   }

  //   .promo-title {
  //     font-size: 1.3rem;
  //   }

  //   .total-discount {
  //     color: $iron-gray;
  //     border: 1px solid $pearl-river;
  //     background: $white;
  //     width: 100%;
  //     font-family: $headings-font-family;
  //     font-size: 1rem;
  //     font-weight: $font-weight-lighter;
  //     margin-top: 1.5rem;
  //     margin-bottom: 1rem;
  //     padding: 1rem;
  //   }
  // }
}

.shipping-change-form-modal {
  .title-section {
    font-weight: $font-weight-light;
    font-size: 1.5rem;
    line-height: 29px;
    color: $iron-gray;
    margin-bottom: 1.5rem;
  }
  .address-list {
    height: 60vh;
    overflow-y: scroll;
    display: grid;
    gap: 1.5rem;
    .address-item {
      padding: 1.5rem;
      border: 1px solid $pearl-river;
      cursor: pointer;
      &.is-primary {
        border: 1px solid $nude-porcelain;
        background-color: rgba($light-porcelain, 0.2);
        .form-check .form-check-input[type="radio"]:checked {
          border-color: $body-color;
          background-color: $white;
          &::after {
            background-color: $body-color;
            width: 0.75rem;
            height: 0.75rem;
            top: 40%;
            left: 40%;
          }
        }
      }
      .label-address {
        font-weight: $font-weight-lighter;
        font-size: 1.25rem;
        line-height: 24px;
        margin-bottom: 1rem;
      }
      .label-choose {
        font-weight: $font-weight-light;
        font-size: 1rem;
        line-height: 19px;
        text-decoration: underline;
      }
      .value-address {
        font-weight: $font-weight-light;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 0;
        color: rgba($body-color, 0.7);
      }
    }
  }
}

.modal-billing-address,
.shipping-form-modal {
  .sc-checkout {
    padding-top: 0;
    .title-content {
      font-weight: $font-weight-light;
      font-size: 1.5rem;
      line-height: 29px;
      color: $iron-gray;
      margin-bottom: 1.5rem;
    }
  }
}
